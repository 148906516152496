import React, {
  useState,
  useReducer,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {
  Table,
  OverlayTrigger,
  Tooltip,
  Container,
  Row,
  Col,
  Tab,
} from 'react-bootstrap';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  advancedLayout,
  unitsDensity,
  minDensity,
  minDensityValue,
  normalDensity,
  normalDensityValue,
  maxDensity,
  maxDensityValue,
  unitsViscosity,
  minViscosity,
  minViscosityValue,
  normalViscosity,
  normalViscosityValue,
  maxViscosity,
  maxViscosityValue,
  unitsVapor,
  minVapor,
  minVaporValue,
  maxFlowAcc,
  unitsMaxPressureDrop,
  maxPressureDrop,
  unitsPressureAtmospheric,
  pressureAtmospheric,
  unitsDensityHeading,
  unitsMinimumAccuracy,
  unitsSonicVelocity,
  nonNewtonianLiquid,
  nonNewtonianLiquiFlag,
  kFlowConsistencyIndex,
  nFlowBehavior,
  unitsGasPressure,
  gasPressure,
  baseCompressibility,
  unitsGasTemperature,
  gasTemperature,
  unitsGasDensity,
  unitsGasDensitySG,
  gasDensity,
  gasDensityValue,
  baseReferenceConditions,
  specificGravity,
  molecularWeight,
  sonicVelocity,
  sonicVelocityValue,
  gasCompressibility,
  clearAllField,
  requestSizingResults,
  displaySizingResults,
  wasClicked,
  savedSizingLoad,
  densitySG,
  densitySGValue,
  minViscosityConvertionError,
  normalViscosityConvertionError,
  maxViscosityConvertionError,
  convertDisableButton,
  resetResizingFlag,
  clearProcVariables,
  fluidSelectionSection,
  gasDensityError,
  normalDensityError,
  normalViscosityError,
  resetOperatingConditions, 
  revertNonNewtonianCheckboxFlag,
  normalDensityMinMaxErrors,
  maxDensityMinMaxErrors,
  normalViscosityMinMaxErrors,
  maxViscosityMinMaxErrors,
  normalDensityTypeErrors,
  maxDensityTypeErrors,
  normalViscosityTypeErrors,
  maxViscosityTypeErrors,
  gasTemperatureError,
  gasPressureError,
} from '../../redux/actions';
import jsonInputs from '../../JSONForTesting/jsonInputs';
import { CustomDropdown } from '../../components/CustomDropdown/CustomDropdown';
import { CustomCheckbox } from '../../components/CustomCheckbox/CustomCheckbox';
import {
  DENSITY_UNIT,
  VISCOSITY_UNIT,
  VAPOR_PRESSURE_UNIT,
  DEFAULT_DECIMAL,
  DENSITY_VALUES,
  GAS_DENSITY_VALUES,
  GAS_DENSITY_SG_VALUES,
  SONIC_VELOCITY_VALUES,
  TEMPERATURE_VALUES,
  VAPOR_PRESSURE_VALUES,
  VISCOSITY_VALUES,
  VISCOSITY_CP_VALUES,
  GAS_ONLY_TEMPERATURE_UNIT,
  GAS_ONLY_DENSITY_UNIT,
  GAS_TEMP_VALUES,
  GAS_PRESSURE_VALUES,
  TEMPERATURE_UNIT,
GAS_ONLY_PRESSURE_UNIT,

} from '../../constants/constants';
import {
  decimalPlaceNumeric,
  fixToDecimalPlaces,
  reg,
} from '../../UtilityFuntions/decimalFactor';
import { getUnitsKeyValue } from '../../UtilityFuntions/getUnitsKeyValue';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import useConvertForUom from '../../UtilityFuntions/useConvertForUom';
import {
  initialFluidPropertyState,
  FluidPropertyReducer,
} from './FluidReducer';
import FormatOppConditionsErrorMessage from '../../components/CustomTooltip/FormatOppConditionsErrorMessage';
import { usePrevious } from '../../UtilityFuntions/usePrevious';
import CustomTranslationText from '../../components/CustomTranslation/CustomTranslationText';


const FluidProperties = (props) => {
  const [decimalPlaces, setDecimalPlaces] = useState(DEFAULT_DECIMAL);
  const [viscosityConvertionFlag, setViscosityConvertionFlag] = useState(false)
  const [resizingFlag, setResizingFlag] = useState(true)
  const [state, dispatch] = useReducer(
    FluidPropertyReducer,
    initialFluidPropertyState
  );

  useEffect(() => {
    setDecimalPlaces(
      props.saved_preferences.all_other_decimal_factor
        ? decimalPlaceNumeric(props.saved_preferences.all_other_decimal_factor)
        : decimalPlaceNumeric(props.decimal_factor_pref)
    );
    dispatch({
      type: 'decimalPlaces',
      payload:  props.saved_preferences.all_other_decimal_factor
      ? decimalPlaceNumeric(props.saved_preferences.all_other_decimal_factor)
      : decimalPlaceNumeric(props.decimal_factor_pref),
    });
  }, [props.saved_preferences]);


 //use Effect to rever the non newtonian liquid checkbox from the ui when discarding a change.
 useEffect(() => {
    if(props.revert_non_newtonian_checkbox_flag){
      if(props.non_newtonian_liquid){
        setTimeout(() => {
          dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
          dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
          dispatch({ type: 'viscosityMax', payload: 'border-disabled' });  
        }, 1000);
        
      }else{
        setTimeout(() => {
          dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
          if(props.select_technology ==='Density' || props.select_technology ==='Viscosity'){
            dispatch({ type: 'viscosityMax', payload: 'border-required' });
          }else{
            dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
          }  
        }, 1000);
       
      }
      
      setTimeout(() => {
        props.revertNonNewtonianCheckboxFlag(false)
      }, 3000);

    } 

 }, [props.revert_non_newtonian_checkbox_flag])
 


  const prevTechnology = usePrevious(state.selectedTechnology);
 
  const setInitialValues = (results) => {
    results.density_unit
      ? props.unitsDensity(getUnitsKeyValue(
        props.units_density,
        'density',
        props.units_data
      ))
      :props.unitsDensity(props.units_density);
    results.viscosity_unit
    ?props.unitsViscosity(getUnitsKeyValue(
      props.units_viscosity,
      'viscosity',
      props.units_data
    ))
    : props.unitsViscosity(props.units_viscosity)
    results.vaporpressure_unit
      ?props.unitsVapor(getUnitsKeyValue(
        props.units_vapor,
        'vaporPressure',
        props.units_data
      ))
      : props.unitsVapor(props.units_vapor)
    results.atmpress_unit
      ?props.unitsPressureAtmospheric( getUnitsKeyValue(
        results.atmpress_unit,
        'vaporPressure',
        props.units_data
      ))
      :props.unitsPressureAtmospheric(props.units_pressure_atmospheric)
    results.gasonly_pressure_unit
      ?props.unitsGasPressure(getUnitsKeyValue(
        results.gasonly_pressure_unit,
        'vaporPressure',
        props.units_data
      ),)
      :props.unitsGasPressure(props.units_gas_pressure)
      results.gasonly_pressure
    ? props.gasPressure( parseFloat(results.gasonly_pressure).toFixed(props.all_other_decimal).toString() )
    : props.gasPressure( parseFloat(props.gas_pressure).toFixed(props.all_other_decimal).toString());


    results.gasonly_temperature_unit
      ?props.unitsGasTemperature( getUnitsKeyValue(
        results.gasonly_temperature_unit,
        'temperature',
        props.units_data
      ))
      : props.unitsGasTemperature(props.units_gas_temperature)

    results.gasonly_temperature
    ?props.gasTemperature(parseFloat(results.gasonly_temperature).toFixed(props.all_other_decimal).toString())
    :props.gasTemperature( parseFloat(props.gas_temperature).toFixed(props.all_other_decimal).toString());
    results.gasonly_density_unit
      ? props.unitsGasDensity( getUnitsKeyValue(
        results.gasonly_density_unit,
        'density',
        props.units_data
      ))
      : props.unitsGasDensity( props.units_gas_density )

    results.sonic_velocity_unit
      ? props.unitsSonicVelocity( getUnitsKeyValue(
        results.sonic_velocity_unit,
        'sonicVelocity',
        props.units_data
      ))
      : props.unitsSonicVelocity(props.units_sonic_velocity)
  };
  
  useEffect(() => {

    if (Object.keys(props.get_sizing_data).length > 0) {
      setResizingFlag(false)
      const fetchData = async () => {
        let data = props.get_sizing_data[0];
        dispatch({
          type: 'selectedTechnology',
          payload: props.select_technology,
        });
        props.nonNewtonianLiquiFlag(true)
        props.unitsDensityHeading(data.densty_col_disp)
        
        await props.minViscosity(reg.test(parseFloat(data.minvisc))?parseFloat(data.minvisc).toFixed(decimalPlaces):'');
        await props.minDensity(reg.test(parseFloat(data.mindens))?parseFloat(data.mindens).toFixed(decimalPlaces):'');
        await props.maxDensity(reg.test(parseFloat(data.maxdens))?parseFloat(data.maxdens).toFixed(decimalPlaces):'');
        await props.normalViscosity(reg.test(parseFloat(data.opvisc))?parseFloat(data.opvisc).toFixed(decimalPlaces):'');
        
        await props.maxViscosity(reg.test(parseFloat(data.maxvisc))?parseFloat(data.maxvisc).toFixed(decimalPlaces):'');
        await props.minVapor(reg.test(parseFloat(data.vapour_pressure))?parseFloat(data.vapour_pressure).toFixed(decimalPlaces):'');
        if(data.densty_col_disp === 'Density'){
          await props.normalDensity(reg.test(parseFloat(data.opdens))?parseFloat(data.opdens).toFixed(decimalPlaces):'');
        }else{
          await props.densitySG(data.opdens ? parseFloat(data.opdens).toFixed(decimalPlaces):'');
        }
        await props.pressureAtmospheric(reg.test(parseFloat(data.atmospheric_pressure))?parseFloat(data.atmospheric_pressure).toFixed(decimalPlaces):'');
        await props.maxFlowAcc(reg.test(parseFloat(data.max_flow_accuracy))?parseFloat(data.max_flow_accuracy).toFixed(decimalPlaces):'');
        await props.maxPressureDrop(reg.test(parseFloat(data.max_pressure_drop))?parseFloat(data.max_pressure_drop).toFixed(decimalPlaces):'');
        await props.sonicVelocity(reg.test(parseFloat(data.sonic_velocity))?parseFloat(data.sonic_velocity).toFixed(decimalPlaces):'');
        await props.unitsSonicVelocity( getUnitsKeyValue(
          data.sonic_velocity_units,
          'sonicVelocity',
          props.units_data
        ))
        dispatch({
          type: 'prevSonicVelocityDropdownKey',
          payload: props.units_data.sonicVelocity.findIndex(
            (p) => p.key === data.sonic_velocity_units
          ),
        });
        dispatch({
          type: 'SonicVelocityDropdownKey',
          payload: props.units_data.sonicVelocity.findIndex(
            (p) => p.key === data.sonic_velocity_units
          ),
        });
        if(!props.saved_preferences_flag){
          await props.gasPressure( data.gas_base_ref_pressure ? parseFloat(data.gas_base_ref_pressure).toFixed(decimalPlaces) : parseFloat(props.gas_pressure).toFixed(decimalPlaces));
          await props.gasTemperature( data.gas_base_ref_temp ? parseFloat(data.gas_base_ref_temp).toFixed(decimalPlaces) : parseFloat(props.gas_temperature).toFixed(decimalPlaces));
          
          if(data.densty_col_disp === 'Density' && data.fluid_source === 'SLURRYNAME'){
            await props.gasDensity(reg.test(parseFloat(data.gas_base_ref_density))?parseFloat(data.gas_base_ref_density).toFixed(decimalPlaces):'');
          }else{
            await props.gasDensity(reg.test(parseFloat(data.gas_base_ref_density))?data.gas_base_ref_density.toString():'');
          }
        }  
        await props.molecularWeight(reg.test(parseFloat(data.molec_weight)) ?parseFloat(data.molec_weight).toFixed(decimalPlaces):'')
        await props.specificGravity(reg.test(parseFloat(data.spec_gravity))? parseFloat(data.spec_gravity).toFixed(decimalPlaces):'' )
        
        await props.gasCompressibility(reg.test(parseFloat(data.compressibility))?parseFloat(data.compressibility).toFixed(decimalPlaces):'' )
        await props.baseCompressibility(reg.test(parseFloat(data.gas_base_ref_compressbility))?parseFloat(data.gas_base_ref_compressbility).toFixed(decimalPlaces):'')
        
        if(data.non_newtonian=== 'Y'){
          toggleNonNewtonLiquidHandle(data.non_newtonian=== 'Y'? true:false)
        }
         props.kFlowConsistencyIndex(reg.test(parseFloat(data.non_newtonian_k))?parseFloat(data.non_newtonian_k).toFixed(decimalPlaces):'');
         props.nFlowBehavior(reg.test(parseFloat(data.non_newtonian_n))?parseFloat(data.non_newtonian_n).toFixed(decimalPlaces):'');
        let density_index= await props.units_data.density.findIndex(
          (p) => p.key === data.densunit
        )

        let ref_pressure_index = await props.units_data.vaporPressure.findIndex(
          (p) => p.key === data.gas_base_ref_pressure_units
        )
        ref_pressure_index = ref_pressure_index !== -1 ? ref_pressure_index : 0

        let ref_temperature_index = await props.units_data.temperature.findIndex(
          (p)=> p.key === data.gas_base_ref_temp_units
        )
        ref_temperature_index = ref_temperature_index !== -1 ? ref_temperature_index : 0

        let ref_density_index = await props.units_data.density.findIndex(
          (p) => p.key === data.gas_base_ref_density_units
        )
        ref_density_index = ref_density_index !== -1 ? ref_density_index : 0
        
        let viscosity_index=await props.units_data.viscosity.findIndex(
          (p) => p.key === data.viscunit
        )
       let vapour_index= await props.units_data.vaporPressure.findIndex(
          (p) => p.key === data.vapour_pressure_units
        )
        if(!props.saved_preferences_flag){
          dispatch({ type: 'prevPressureDropdownKey', payload: ref_pressure_index });
          dispatch({ type: 'uomPressureKey', payload: ref_pressure_index });

          dispatch({ type: 'prevTemperatureDropdownKey', payload: ref_temperature_index });
          dispatch({ type: 'uomTemperatureKey', payload: ref_temperature_index });

          dispatch({ type: 'prevGasDensityDropdownKey', payload: ref_density_index });
          dispatch({ type: 'uomGasDensityKey', payload: ref_density_index });

          await props.unitsGasDensity(props.units_data ?props.units_data.density[ref_density_index]:{key: 'KG/M3', value: 'kg/m3'})
          await props.unitsGasPressure(props.units_data ? props.units_data.vaporPressure[ref_pressure_index]:{key:'ATM-A', value: 'atm-a'})
          await props.unitsGasTemperature(props.units_data ? props.units_data.temperature[ref_temperature_index]:{key:'C', value: 'c'})
        }
        if(data.densty_col_disp === 'Density'){
          await props.unitsDensity(props.units_data.density[density_index])
        }else{
       
          await props.unitsGasDensitySG(props.units_data? props.units_data.density[density_index]:{key: 'KG/M3', value: 'kg/m3'})
        }
        await props.unitsViscosity( props.units_data.viscosity[viscosity_index]) 
        await props.unitsVapor(props.units_data.vaporPressure[vapour_index]) 
        await props.setCalcCall(false)
        await props.savedSizingLoad(true);
        props.setCalcCall(false)
        setTimeout(() => {
          props.nonNewtonianLiquiFlag(false)
        }, 2000);

      };
      fetchData();
    }
    setTimeout(() => {
      setResizingFlag(true)  
    }, 3000);
    
  }, [props.get_sizing_data]);

  //dispatch input errors
  useEffect(() => {
    //errores 
    if (props.minimum_density_error) {
      dispatch({
        type:'minDensityError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'densityMin', payload: 'border-error'})
    }
    if(props.normal_density_error){
      dispatch({
        type: 'normalDensityError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'densityNormal', payload: 'border-error' });
    }
    if (props.maximum_density_error) {
      dispatch({
        type:'maxDensityError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'densityMax', payload: 'border-error'})
    }
    if(props.gas_density_error){
      dispatch({
        type: 'gasDensityError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'gasDensity', payload: 'border-error' });
    }

    if(props.gas_pressure_error){
      dispatch({
        type: 'gasPressureError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'gasPressure', payload: 'border-error' });
    }

    if(props.gas_temperature_error){
      dispatch({
        type: 'gasTemperatureError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'gasTemperature', payload: 'border-error' });
    }

    if (props.normal_viscosity_error) {
      dispatch({
        type:'normalViscosityError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'viscosityNormal', payload: 'border-error'})
    }
    if (props.maximum_viscosity_error) {
      dispatch({
        type:'maxViscosityError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'viscosityMax', payload: 'border-error'})
    }
    if(props.gas_custom_compressibility_error){
      dispatch({
        type: 'gasCustomCompressibilityError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({ type: 'gasCustomCompressibility', payload: 'border-error' });
    }
    if(props.molecular_weight_error){
      dispatch({
        type: 'molecularWeightError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({type:'molecularWeight', payload: 'border-error'});
    }

    if(props.specific_gravity_error){
      dispatch({
        type: 'specificGravityError',
        payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
      });
      dispatch({type:'specificGravity', payload: 'border-error'});
    }

    if(props.base_compressibility_error){
      dispatch({type:'baseCompressibility', payload: 'border-error'});
    }

  }, [props.normal_density_error, props.gas_density_error, props.minimum_density_error, props.maximum_density_error, 
    props.normal_viscosity_error, props.maximum_viscosity_error, props.gas_custom_compressibility_error, 
    props.molecular_weight_error, props.gas_temperature_error, props.gas_pressure_error ]);


  const handleError = (value) => {
    switch (value) {
      case 'gasDensity':
        if (props.gas_density) {
          dispatch({
            type: 'gasDensityError',
            payload: '',
          });
          dispatch({ type: 'gasDensity', payload: 'border-required' });
        } else {
          dispatch({
            type: 'gasDensityError',
            payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props),],
          });
          dispatch({ type: 'gasDensity', payload: 'border-error' });
        }
        break;
      case 'minDensity':  
        
        if(parseFloat(props.min_density)  > parseFloat(props.normal_density)){
          dispatch({ type: 'densityNormal', payload: 'border-error' });
          props.normalDensityMinMaxErrors(true) 
          props.normalDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG107', props))
          dispatch({
            type: 'normalDensityError',
            payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG107', props)],
          });
          
          if(parseFloat(props.min_density)  > parseFloat(props.max_density)){
            dispatch({ type: 'densityMax', payload: 'border-error' });
            props.maxDensityMinMaxErrors(true)
            props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG108', props))
            dispatch({
              type: 'maxDensityError',
              payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG108', props)],
            });
          }
        }else if(props.max_density !==''){
          if(props.min_density ===''){
            dispatch({ type: 'densityNormal', payload: 'border-required' });
            props.normalDensityMinMaxErrors(false)
            props.normalDensityTypeErrors('');
            dispatch({
              type: 'normalDensityError',
              payload: '',
            });

            if(props.normal_density !==''){
              if(parseFloat(props.normal_density) > parseFloat(props.max_density)){
                dispatch({type: 'densityMax', payload: 'border-error'})
                props.maxDensityMinMaxErrors(true)
                props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props))
                dispatch({
                  type: 'maxDensityError',
                  payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props)],
                });
  
                
              }else{
                if(props.density || props.viscosity || props.calculate_density_variation){ 
                  dispatch({type: 'densityMax', payload: 'border-required'})
                  props.maxDensityMinMaxErrors(false)
                  props.maxDensityTypeErrors('')
                  dispatch({
                    type: 'maxDensityError',
                    payload: '',
                  });
                }else{
                    dispatch({type: 'densityMax', payload: 'border-not-required'})
                    props.maxDensityMinMaxErrors(false)
                    props.maxDensityTypeErrors('')
                    dispatch({
                      type: 'maxDensityError',
                      payload: '',
                    });
                }
              }
            }else{
              if(props.density || props.viscosity || props.calculate_density_variation){ 
                dispatch({type: 'densityMax', payload: 'border-required'})
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }else{
                  dispatch({type: 'densityMax', payload: 'border-not-required'})
                  props.maxDensityMinMaxErrors(false)
                  props.maxDensityTypeErrors('')
                  dispatch({
                    type: 'maxDensityError',
                    payload: '',
                  });
              }
            }
            
          }else{
            if(parseFloat(props.min_density)  > parseFloat(props.max_density)){
              dispatch({ type: 'densityMax', payload: 'border-error' });
              props.maxDensityMinMaxErrors(true)
              props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG108', props))
              dispatch({
                type: 'maxDensityError',
                payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG108', props)],
              });
              if(parseFloat(props.min_density)<= parseFloat(props.normal_density)){
                dispatch({ type: 'densityNormal', payload: 'border-required' });
                props.normalDensityMinMaxErrors(false)
                props.normalDensityTypeErrors('')
                dispatch({
                  type: 'normalDensityError',
                  payload: '',
                });
              }
            }else{
              if(props.density || props.viscosity || props.calculate_density_variation){ 
                if(parseFloat(props.min_density)  <= parseFloat(props.max_density)){
                  if(parseFloat(props.normal_density)<= parseFloat(props.max_density)){
                    if(props.density || props.viscosity || props.calculate_density_variation){ 
                      dispatch({type: 'densityMax', payload: 'border-required'})
                      props.maxDensityMinMaxErrors(false)
                      props.maxDensityTypeErrors('')
                      dispatch({
                        type: 'maxDensityError',
                        payload: '',
                      });
                    }else{
                      dispatch({type: 'densityMax', payload: 'border-not-required'})
                      props.maxDensityMinMaxErrors(false)
                      props.maxDensityTypeErrors('')
                      dispatch({
                        type: 'maxDensityError',
                        payload: '',
                      });
                    }
                  }else{
                    dispatch({type: 'densityMax', payload: 'border-error'})
                    props.maxDensityMinMaxErrors(true)
                    props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props))
                    dispatch({
                      type: 'maxDensityError',
                      payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props)],
                    });
                   
                  }
                }
                if(parseFloat(props.min_density) <= parseFloat(props.normal_density)){
                  dispatch({ type: 'densityNormal', payload: 'border-required' });
                  props.normalDensityMinMaxErrors(false)
                  props.normalDensityTypeErrors('')
                  dispatch({
                    type: 'normalDensityError',
                    payload: '',
                  });
                }
    
              }else{
                  if(parseFloat(props.min_density)  <= parseFloat(props.max_density)){
                    if(props.normal_density !== ''){
                      if(parseFloat(props.normal_density)<= parseFloat(props.max_density)){
                        if(props.density || props.viscosity || props.calculate_density_variation){ 
                          dispatch({type: 'densityMax', payload: 'border-required'})
                          props.maxDensityMinMaxErrors(false)
                          props.maxDensityTypeErrors('')
                          dispatch({
                            type: 'maxDensityError',
                            payload: '',
                          });
                        }else{
                          dispatch({type: 'densityMax', payload: 'border-not-required'})
                          props.maxDensityMinMaxErrors(false)
                          props.maxDensityTypeErrors('')
                          dispatch({
                            type: 'maxDensityError',
                            payload: '',
                          });
                        }
                      }else{
                        dispatch({type: 'densityMax', payload: 'border-error'})
                        props.maxDensityMinMaxErrors(true)
                        props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props))
                        dispatch({
                          type: 'maxDensityError',
                          payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props)],
                        });
                       
                      }
                    }else{
                      dispatch({type: 'densityMax', payload: 'border-required'})
                      props.maxDensityMinMaxErrors(false)
                      props.maxDensityTypeErrors('')
                      dispatch({
                        type: 'maxDensityError',
                        payload: '',
                      });
                    }
                    
                  }
                  if(parseFloat(props.min_density) <= parseFloat(props.normal_density)){
                    dispatch({ type: 'densityNormal', payload: 'border-required' });
                    props.normalDensityMinMaxErrors(false)
                    props.normalDensityTypeErrors('')
                    dispatch({
                      type: 'normalDensityError',
                      payload: '',
                    });
                  }
              }
            }
          }
        }else if(parseFloat(props.min_density)  <= parseFloat(props.normal_density)){
          dispatch({ type: 'densityNormal', payload: 'border-required' });
          props.normalDensityMinMaxErrors(false)
          props.normalDensityTypeErrors('')
          dispatch({
            type: 'normalDensityError',
            payload: '',
          });
          if(props.max_density !==''){
            if(parseFloat(props.min_density)  <= parseFloat(props.max_density)){
              if(props.density || props.viscosity || props.calculate_density_variation){ 
                if(parseFloat(props.normal_density)  <= parseFloat(props.max_density)){
                  dispatch({type: 'densityMax', payload: 'border-required'})
                  props.maxDensityMinMaxErrors(false)
                  props.maxDensityTypeErrors('')
                  dispatch({
                    type: 'maxDensityError',
                    payload: '',
                  });
                }
              }else{
                if(parseFloat(props.normal_density)  <= parseFloat(props.max_density)){
                  dispatch({type: 'densityMax', payload: 'border-not-required'})
                  props.maxDensityMinMaxErrors(false)
                  props.maxDensityTypeErrors('')
                  dispatch({
                    type: 'maxDensityError',
                    payload: '',
                  });
                }
              }
            }else{
              dispatch({ type: 'densityMax', payload: 'border-error' });
              props.maxDensityMinMaxErrors(true)
              props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG108', props))
              dispatch({
                type: 'maxDensityError',
                payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG108', props)],
              });
            }
          }
          
        }
        if(!props.min_density && props.calculate_density_variation ){
          dispatch({
            type: 'minDensityError',
            payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'densityMin', payload: 'border-error' });
        }else{
          if(props.calculate_density_variation){
            dispatch({
              type: 'minDensityError',
              payload: '',
            });
            dispatch({ type: 'densityMin', payload: 'border-required' });
          }else{
            dispatch({
              type: 'minDensityError',
              payload: '',
            });
            dispatch({ type: 'densityMin', payload: 'border-not-required' });
          }
          
        }

        break;
      case 'normalDensity':
          if (props.normal_density) {
            if(parseFloat(props.normal_density) < parseFloat(props.min_density)){
              dispatch({ type: 'densityNormal', payload: 'border-error' });
              props.normalDensityMinMaxErrors(true)
              props.normalDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG107', props))
              dispatch({
                type: 'normalDensityError',
                payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG107', props)],
              });
            }else if(parseFloat(props.normal_density) >= parseFloat(props.min_density)){
              dispatch({ type: 'densityNormal', payload: 'border-required' });
              props.normalDensityMinMaxErrors(false)
              props.normalDensityTypeErrors('')
              dispatch({
                type: 'normalDensityError',
                payload: '',
              });
      
              if(props.density || props.viscosity || props.calculate_density_variation){
 
                if(parseFloat(props.normal_density) <= parseFloat(props.max_density)){
                  dispatch({type: 'densityMax', payload: 'border-required'})
                  props.maxDensityMinMaxErrors(false)
                  props.maxDensityTypeErrors('')
                  dispatch({
                    type: 'normalDensityError',
                    payload: '',
                  });
                  dispatch({
                    type: 'maxDensityError',
                    payload: '',
                  });
          
                }else{
                  if(props.max_density !==''){
                    dispatch({type: 'densityMax', payload: 'border-error'})
                    props.maxDensityMinMaxErrors(true)
                    props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props))
                    dispatch({
                      type: 'maxDensityError',
                      payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props)],
                    });
                  }
                  
                }


              }else{
                if(parseFloat(props.normal_density) <= parseFloat(props.max_density)){
                  dispatch({type: 'densityMax', payload: 'border-not-required'})
                  props.maxDensityMinMaxErrors(false)
                  props.maxDensityTypeErrors('')
                  dispatch({
                    type: 'maxDensityError',
                    payload: '',
                  });      
                }else{
                  if(props.max_density !==''){
                    dispatch({type: 'densityMax', payload: 'border-error'})
                    props.maxDensityMinMaxErrors(true)
                    props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props))
                    dispatch({
                      type: 'maxDensityError',
                      payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props)],
                    });
                  }
                  
                
                }
              }
              
            }else if(parseFloat(props.normal_density) > parseFloat(props.max_density)){
              dispatch({type: 'densityMax', payload: 'border-error'})
              props.maxDensityMinMaxErrors(true)
              props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props))
              dispatch({
                type: 'maxDensityError',
                payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props)],
              });
              if(props.min_density !== ''){
                if(parseFloat(props.min_density<= parseFloat(props.normal_density))){
                  dispatch({
                    type: 'normalDensityError',
                    payload: '',
                  });
                  dispatch({ type: 'densityNormal', payload: 'border-required' });
                  props.normalDensityMinMaxErrors(false)
                  props.normalDensityTypeErrors('')
                  
                }
              }else{
                  dispatch({
                    type: 'normalDensityError',
                    payload: '',
                  });
                  dispatch({ type: 'densityNormal', payload: 'border-required' });
                  props.normalDensityMinMaxErrors(false)
                  props.normalDensityTypeErrors('')
                
              }
              
              
              
            }else if((parseFloat(props.normal_density) >= parseFloat(props.min_density))&&(parseFloat(props.normal_density) <= parseFloat(props.max_density))){
              dispatch({ type: 'densityNormal', payload: 'border-required' });
              props.normalDensityMinMaxErrors(false)
              props.normalDensityTypeErrors('')
              dispatch({
                type: 'normalDensityError',
                payload: '',
              });
              if(props.density || props.viscosity || props.calculate_density_variation){   
                dispatch({type: 'densityMax', payload: 'border-required'})
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }else{
                dispatch({type: 'densityMax', payload: 'border-not-required'})
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }
            }else if(parseFloat(props.normal_density) <= parseFloat(props.max_density)){
              dispatch({ type: 'densityNormal', payload: 'border-required' });
              dispatch({
                type: 'normalDensityError',
                payload: '',
              });

              if(props.density || props.viscosity || props.calculate_density_variation){   
                dispatch({type: 'densityMax', payload: 'border-required'})
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }else{
                dispatch({type: 'densityMax', payload: 'border-not-required'})
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }
            }
            
            else {
              dispatch({ type: 'densityNormal', payload: 'border-required' });
              props.normalDensityMinMaxErrors(false)
              props.normalDensityTypeErrors('')
            }
           
          } else {
            dispatch({
              type: 'normalDensityError',
              payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
            });
            dispatch({ type: 'densityNormal', payload: 'border-error' });
            props.normalDensityTypeErrors('')

            if(parseFloat(props.max_density) >= parseFloat(props.min_density)|| props.min_density === '' ){
              if(props.density || props.viscosity || props.calculate_density_variation){ 
                dispatch({type: 'densityMax', payload: 'border-required'})
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }else{
                  dispatch({type: 'densityMax', payload: 'border-not-required'})
                  props.maxDensityMinMaxErrors(false)
                  props.maxDensityTypeErrors('')
                  dispatch({
                    type: 'maxDensityError',
                    payload: '',
                  });
              }
            }

          }
          
        break;
      case 'maxDensity':
          if (props.max_density) {
            if(parseFloat(props.max_density) < parseFloat(props.normal_density)){
              dispatch({ type: 'densityMax', payload: 'border-error' });
              props.maxDensityMinMaxErrors(true)
              props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props))
              dispatch({
                type: 'maxDensityError',
                payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG106', props)],
              });
            }else if(parseFloat(props.max_density) < parseFloat(props.min_density)){
              dispatch({ type: 'densityMax', payload: 'border-error' });
              props.maxDensityMinMaxErrors(true)
              props.maxDensityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG108', props))
              dispatch({
                type: 'maxDensityError',
                payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG108', props)],
              });
            }else if(parseFloat(props.max_density) >= parseFloat(props.normal_density)){
              if(props.viscosity || props.density|| props.calculate_density_variation){

                if(props.flow){
                  dispatch({ type: 'densityMax', payload: 'border-not-required' });
                }else{
                  dispatch({ type: 'densityMax', payload: 'border-required' });
                }
                

                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });



              }else{
                dispatch({ type: 'densityMax', payload: 'border-not-required' });
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }
            }else if(parseFloat(props.max_density) >= parseFloat(props.min_density)){
              if(props.viscosity || props.density|| props.calculate_density_variation){
                if(props.flow){
                  dispatch({ type: 'densityMax', payload: 'border-not-required' });
                }else{
                  dispatch({ type: 'densityMax', payload: 'border-required' });
                }
                
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }else{
                dispatch({ type: 'densityMax', payload: 'border-not-required' });
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }
            }
            if(props.normal_density ===''&& props.min_density ===''){
              if(props.viscosity || props.density|| props.calculate_density_variation){

                if(props.flow){
                  dispatch({ type: 'densityMax', payload: 'border-not-required' });
                }else{
                  dispatch({ type: 'densityMax', payload: 'border-required' });
                }
                
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });

              }else{
                dispatch({ type: 'densityMax', payload: 'border-not-required' });
                props.maxDensityMinMaxErrors(false)
                props.maxDensityTypeErrors('')
                dispatch({
                  type: 'maxDensityError',
                  payload: '',
                });
              }
            }
          
          } else {
            if(props.viscosity || props.density|| props.calculate_density_variation){
              if(!props.flow){
                dispatch({
                  type: 'maxDensityError',
                  payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
                });
                dispatch({ type: 'densityMax', payload: 'border-error' });
              }else{
                dispatch({
                  type: 'maxDensityError',
                  payload:'',
                });
                dispatch({ type: 'densityMax', payload: 'border-not-required' });
              }
              
              
              props.maxDensityTypeErrors('')
              
            }else{
              dispatch({
                type: 'maxDensityError',
                payload:'',
              });
              dispatch({ type: 'densityMax', payload: 'border-not-required' });
              props.maxDensityTypeErrors('')
              props.maxDensityMinMaxErrors(false)
            }
            
          }
        break;
      case 'minViscosity':
        if (props.min_viscosity) {
          if (parseFloat(props.min_viscosity) > parseFloat(props.normal_viscosity)) {
            dispatch({ type: 'viscosityNormal', payload: 'border-error' });
            props.normalViscosityMinMaxErrors(true)
            props.normalViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG110', props))
            dispatch({
              type: 'normalViscosityError',
              payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG110', props)],
            });
          } 
          if (parseFloat(props.min_viscosity) > parseFloat(props.max_viscosity)) {
            dispatch({ type: 'viscosityMax', payload: 'border-error' });
            props.maxViscosityMinMaxErrors(true)
            props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props))
            dispatch({
              type: 'maxViscosityError',
              payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props)],
            });
          }
          if((parseFloat(props.min_viscosity) <= parseFloat(props.normal_viscosity)) && (parseFloat(props.min_viscosity) <= parseFloat(props.max_viscosity))){
            dispatch({
              type: 'normalViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityNormal', payload: 'border-required' });
            props.normalViscosityMinMaxErrors(false)  
            props.normalViscosityTypeErrors('') 
            
            dispatch({
              type: 'maxViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
            props.maxViscosityMinMaxErrors(false)  
            props.maxViscosityTypeErrors('') 
          }
        }
        break;
      case 'normalViscosity':
        if(!props.normal_viscosity_convertion_error){
          if (props.normal_viscosity) {
            dispatch({
              type: 'normalViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityNormal', payload: 'border-required' });
          } else  {
            dispatch({
              type: 'normalViscosityError',
              payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
            });
            dispatch({ type: 'viscosityNormal', payload: 'border-error' });
          }
        }
        if (props.normal_viscosity) {
          if (parseFloat(props.normal_viscosity) < parseFloat(props.min_viscosity)) {
            dispatch({ type: 'viscosityNormal', payload: 'border-error' });
            props.normalViscosityMinMaxErrors(true)
            props.normalViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG110', props))
            dispatch({
              type: 'normalViscosityError',
              payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG110', props)],
            });
          }
          if (parseFloat(props.normal_viscosity) > parseFloat(props.max_viscosity)) {
            dispatch({ type: 'viscosityMax', payload: 'border-error' });
            props.maxViscosityMinMaxErrors(true)
            props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props))
            dispatch({
              type: 'maxViscosityError',
              payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props)],
            });
          }
          if (parseFloat(props.min_viscosity) <= parseFloat(props.normal_viscosity) && parseFloat(props.normal_viscosity) <= parseFloat(props.max_viscosity) && parseFloat(props.min_viscosity) <= parseFloat(props.max_viscosity)) {
            dispatch({
              type: 'normalViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityNormal', payload: 'border-required' });
            props.normalViscosityMinMaxErrors(false)
            props.normalViscosityTypeErrors('')

            dispatch({
              type: 'maxViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
            props.maxViscosityMinMaxErrors(false)
            props.maxViscosityTypeErrors('')
          }
        }  
        break;
      case 'maxViscosity':
        if(!props.max_viscosity_convertion_error){
          if(props.viscosity || props.density){
           
            if (props.max_viscosity) {
              dispatch({
                type: 'maxViscosityError',
                payload: '',
              });
              dispatch({ type: 'viscosityMax', payload: 'border-required' });
            } else  {
              if(!props.flow){
                dispatch({
                  type: 'maxViscosityError',
                  payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
                });
                dispatch({ type: 'viscosityMax', payload: 'border-error' });
              }else{
                dispatch({
                  type: 'maxViscosityError',
                  payload:'',
                });
                dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
              }
            }
          }
        }
        if (props.max_viscosity) {
          if (parseFloat(props.max_viscosity) < parseFloat(props.normal_viscosity)) {
            dispatch({ type: 'viscosityMax', payload: 'border-error' });
            props.maxViscosityMinMaxErrors(true)
            props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props))
            dispatch({
              type: 'maxViscosityError',
              payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props)],
            });
          }
          if (parseFloat(props.max_viscosity) < parseFloat(props.min_viscosity)) {
            dispatch({ type: 'viscosityMax', payload: 'border-error' });
            props.maxViscosityMinMaxErrors(true)
            props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props))
            dispatch({
              type: 'maxViscosityError',
              payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props)],
            });
          }
          if (parseFloat(props.min_viscosity) <= parseFloat(props.normal_viscosity) && parseFloat(props.normal_viscosity) <= parseFloat(props.max_viscosity) && parseFloat(props.min_viscosity) <= parseFloat(props.max_viscosity)) {
            dispatch({
              type: 'normalViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityNormal', payload: 'border-required' });
            props.normalViscosityMinMaxErrors(false)
            props.normalViscosityTypeErrors('')

            dispatch({
              type: 'maxViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
            props.maxViscosityMinMaxErrors(false)
            props.maxViscosityTypeErrors('')
          }
        }
      break;
      case 'compressibilitySG':
        if(props.compressibility){
          dispatch({
            type: 'gasCustomCompressibilityError',
            payload: '',
          });
          dispatch({type: 'gasCustomCompressibility', payload:'border-required'});
        }else{
          dispatch({
            type: 'gasCustomCompressibilityError',
            payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props),],
          });
          dispatch({type: 'gasCustomCompressibility', payload:'border-error'});
          }
        break;
      case 'molecularWeight':
          if(props.molecular_weight){
            dispatch({
              type: 'molecularWeightError',
              payload:'',
            });
            dispatch({type:'molecularWeight', payload: 'border-required'});
          }else {
            dispatch({
              type: 'molecularWeightError',
              payload: [CustomTranslationText('XXSPECIFY_MMI_SR_LBL_ERRMSG13', props)],
            });
            dispatch({type:'molecularWeight', payload: 'border-error'});
          }
      break;
      case 'baseCompressibility':
          if(props.base_compressibility){
            dispatch({type:'baseCompressibility', payload: 'border-required'});
          }else {
            dispatch({type:'baseCompressibility', payload: 'border-error'});
          }
      break;
      default:
      throw new Error('Unexpected case');
    }
  };

  const handleBlur = (event) => {
    switch (event.target.id) { 
      case 'min-density':
        if(state.densityMin !== 'border-disabled'){
          reg.test(parseFloat(props.min_density))
          ? props.minDensity(
              parseFloat(props.min_density).toFixed(decimalPlaces)
            )
          : props.minDensity('');
        }
        if (state.densityMin !== 'border-disabled') {
          handleError('minDensity');          
        }
        if (props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom' && props.minDensity !== '') {
          props.setCalcCall(true)
        }
        break;
      case 'normal-density':
        if (state.densityNormal !== 'border-disabled') {
          isNaN(parseFloat(event.target.value))
          ? props.normalDensity('') : props.normalDensity(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            ) 
          handleError('normalDensity');
        }
        if (props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom' && props.normalDensity !== '') {
          props.setCalcCall(true)
        }
        break;
      case 'max-density':
        if (state.densityMax !== 'border-disabled' ) {
          reg.test(parseFloat(event.target.value))
          ? props.maxDensity(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            )
          : props.maxDensity('');
          handleError('maxDensity');
        }
        if (props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom' && props.maxDensity !== '') {
          props.setCalcCall(true)
        }
        break;
      case 'specific-gravity':
        isNaN(parseFloat(event.target.value))
          ? props.specificGravity('')
          : props.specificGravity(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            );

        if (event.target.value !== '') {
          dispatch({
            type: 'specificGravityError',
            payload: '',
          });
          dispatch({ type: 'specificGravity', payload: 'border-required' });
        } else {
          dispatch({
            type: 'specificGravityError',
            payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'specificGravity', payload: 'border-error' });
        }
        props.setCalcCall(true)
        break;
      case 'molecular-weight':
        isNaN(parseFloat(event.target.value))
          ? props.molecularWeight('')
          : props.molecularWeight(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            );
            if(state.molecularWeight !== 'border-disabled'){
              handleError('molecularWeight')
              props.setCalcCall(true)
            }
            
        break;
      case 'min-viscosity':
        if(state.viscosityMin !=='border-disabled'){
          reg.test(parseFloat(event.target.value))
          ? props.minViscosity(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            )
          : props.minViscosity('');
            handleError('minViscosity');          
        }
       
          if(!props.non_newtonian_liquid){
            props.minViscosityConvertionError(false)
            setViscosityConvertionFlag(true);
            props.convertDisableButton(true)
          }
        break;
      case 'normal-viscosity':
        
        if (state.viscosityNormal !== 'border-disabled') {
          reg.test(parseFloat(event.target.value))
          ? props.normalViscosity(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            )
          : props.normalViscosity('');
          handleError('normalViscosity');
        }
        if(!props.non_newtonian_liquid){
            props.normalViscosityConvertionError(false);
            setViscosityConvertionFlag(true);
            props.convertDisableButton(true);
        }
        break;
      case 'max-viscosity':
        if (state.viscosityMax !== 'border-disabled') {
          reg.test(parseFloat(event.target.value))
          ? props.maxViscosity(parseFloat(event.target.value).toFixed(decimalPlaces))
          : props.maxViscosity('');
          
          handleError('maxViscosity');
        }

        if(!props.non_newtonian_liquid&&event.target.value!==''){
          props.maxViscosityConvertionError(false)
          setViscosityConvertionFlag(true);
          props.convertDisableButton(true);
        }
         
         
        break;
      case 'pressure-atmospheric':
        isNaN(parseFloat(event.target.value))
          ? props.pressureAtmospheric('')
          : props.pressureAtmospheric(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            );
        break;
      case 'min-vapor-pressure':
        if(state.vaporPressureMin !== 'border-disabled'){
          reg.test(parseFloat(event.target.value))
          ?props.minVapor(parseFloat(event.target.value).toFixed(decimalPlaces))
          : props.minVapor('');
        }
        
        break;
      case 'max-flow-acc':
        isNaN(parseFloat(event.target.value))
          ? props.maxFlowAcc('')
          : props.maxFlowAcc(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            );
        break;
      case 'max-pressure-drop':
        isNaN(parseFloat(event.target.value))
          ? props.maxPressureDrop('')
          : props.maxPressureDrop(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            );
        break;
      case 'gas-pressure':
        isNaN(parseFloat(event.target.value))
          ? props.gasPressure('')
          : props.gasPressure(parseFloat(event.target.value).toFixed(
                decimalPlaces
              ));
        props.setCalcCall(true)

        if (event.target.value !== '') {
          dispatch({
            type: 'gasPressureError',
            payload: '',
          });
          dispatch({ type: 'gasPressure', payload: 'border-required' });
        } else {
          dispatch({
            type: 'gasPressureError',
            payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'gasPressure', payload: 'border-error' });
        }


        break;
      case 'gas-temperature':
        isNaN(parseFloat(event.target.value))
        ?props.gasTemperature('')
        :props.gasTemperature(parseFloat(event.target.value).toFixed(
            decimalPlaces));
        props.setCalcCall(true)
        
        if (event.target.value !== '') {
          dispatch({
            type: 'gasTemperatureError',
            payload: '',
          });
          dispatch({ type: 'gasTemperature', payload: 'border-required' });
        } else {
          dispatch({
            type: 'gasTemperatureError',
            payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'gasTemperature', payload: 'border-error' });
        }


        break;
      case 'base-compressibility':
        isNaN(parseFloat(event.target.value))?
        props.baseCompressibility(''):
        props.baseCompressibility(parseFloat(event.target.value).toFixed(
            decimalPlaces
        ));
        if (state.baseCompressibility !== 'border-disabled') {
          handleError('baseCompressibility');
          props.setCalcCall(true)
        }
        break;
      case 'gas-density':
        
          if (state.gasDensity !== 'border-disabled') {
            isNaN(parseFloat(event.target.value))
          ? props.gasDensity('')
          : props.gasDensity(
              parseFloat(event.target.value).toFixed(decimalPlaces)
            );
              handleError('gasDensity');
          }
          if (props.fluid_state.toLowerCase() === 'gas' && props.fluid_source.toLowerCase() === 'custom' && props.gasDensity !== '') {
            props.setCalcCall(true)
          }  
        break;
      case 'sonic-velocity':
        if(state.velocitySonic !== 'border-disabled'){
          reg.test(parseFloat(event.target.value))
          ?  props.sonicVelocity(
              parseFloat(event.target.value).toFixed(state.decimalPlaces)
            ):props.sonicVelocity('')
        }
            
        break;
      case 'compressibility':
        isNaN(parseFloat(event.target.value))
          ? props.gasCompressibility('')
          : props.gasCompressibility(
              parseFloat(event.target.value).toFixed(state.decimalPlaces)
            );
            
            if(state.gasCustomCompressibility !== 'border-disabled'){
              handleError('compressibilitySG')
            }
            props.setCalcCall(true)
        break;
      case 'k-flow-consistency-index':
        isNaN(parseFloat(event.target.value))
          ? props.kFlowConsistencyIndex('')
          : props.kFlowConsistencyIndex(
              parseFloat(event.target.value).toFixed(state.decimalPlaces)
            );

        if (event.target.value !== '') {
          dispatch({
            type: 'kFlowConsistencyIndexError',
            payload: '',
          });
          dispatch({
            type: 'kFlowConsistencyIndex',
            payload: 'border-required',
          });
        } else {
          dispatch({
            type: 'kFlowConsistencyIndexError',
            payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'kFlowConsistencyIndex', payload: 'border-error' });
        }
        break;
      case 'n-flow-behavior':
        isNaN(parseFloat(event.target.value))
          ? props.nFlowBehavior('')
          : props.nFlowBehavior(
              parseFloat(event.target.value).toFixed(state.decimalPlaces)
            );
        if (event.target.value !== '') {
          dispatch({
            type: 'nFlowBehaviorError',
            payload: '',
          });
          dispatch({ type: 'nFlowBehavior', payload: 'border-required' });
        } else {
          dispatch({
            type: 'nFlowBehaviorError',
            payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'nFlowBehavior', payload: 'border-error' });
        }
        break;
      default:
    }
  };


  const unitsDropdown = (
    title,
    id,
    text,
    dropdownArray,
    eventHandler,
    disabled,
    required,
    error,
    showDisabledText
  ) => {
    const btnDropdownStyles =
      id === 'units-density-heading'
        ? 'btn-dropdown-small float-right'
        : 'btn-dropdown-small';
    if (
      id === 'units-density-heading' &&
      (props.fluid_state.toLowerCase() === 'liquid' || props.fluid_state.toLowerCase() === 'slurry')
    ) {
      dropdownArray.splice(2, 1); // remove Molecular Weight
    }

    return (
      <CustomDropdown
        title={title}
        id={id}
        text={text}
        dropdownArray={dropdownArray ? dropdownArray.sort() : ['']}
        eventHandler={eventHandler}
        disabled={disabled}
        borderRequired={required}
        borderError={error}
        btnDropdownStyles={btnDropdownStyles}
        showDisabledText={showDisabledText}
        lowercaseValues={true}
        active={text === 'Density' ? 'Density' : ''}
      />
    );
  };

  const toggleNonNewtonLiquidHandle = (checked) => {
    if (checked) {
      dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
      dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
      dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
      dispatch({ type: 'normalViscosityError', payload: '' });
       
      dispatch({ type: 'kFlowConsistencyIndexError', payload: '' });
      dispatch({ type: 'nFlowBehaviorError', payload: '' });
      dispatch({ type: 'kFlowConsistencyIndex', payload: 'border-required' });
      dispatch({ type: 'nFlowBehavior', payload: 'border-required' });
      dispatch({ type: 'specificGravityError', payload: '' });
      dispatch({ type: 'specificGravity', payload: 'border-required' });
      
    
        dispatch({
          type: 'minViscosityError',
          payload: '',
        });
        dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
        props.minViscosityConvertionError(false);
        dispatch({
          type: 'normalViscosityError',
          payload: '',
        });
        dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
        props.normalViscosityConvertionError(false);

        if(props.viscosity || props.density){
          dispatch({
            type: 'maxViscosityError',
            payload: '',
          });
          dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
          props.maxViscosityConvertionError(false);
      }

      if(!props.non_newtonian_liquid_flag){
        props.kFlowConsistencyIndex('');
        props.nFlowBehavior('');
        props.normalViscosity('');
        props.minViscosity('')
        props.maxViscosity('')
      }
      
    } else {
        props.nonNewtonianLiquid(true)
        if(!props.non_newtonian_liquid_flag){
          props.nonNewtonianLiquid(true)
          props.normalViscosity('');
          props.minViscosity('')
          props.maxViscosity('')
            
        }
        if(props.fluid_source.toLowerCase()==='custom'){
          dispatch({
            type: 'minViscosityError',
            payload: '',
          });
          dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
          props.minViscosityConvertionError(false);

          dispatch({
            type: 'normalViscosityError',
            payload: '',
          });

          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
          props.normalViscosityConvertionError(false);

        if(props.viscosity || props.density){
            dispatch({
              type: 'maxViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityMax', payload: 'border-required' });
            props.maxViscosityConvertionError(false);

          }else{
            dispatch({
              type: 'maxViscosityError',
              payload: '',
            });
            dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
            props.maxViscosityConvertionError(false);
          }
        } 

    }
    props.nonNewtonianLiquid(checked);
  };


  useEffect(() => {
    if(props.clear_all_field){
      dispatch({type: 'prevDensityDropdownKey',payload:-1, });
      dispatch({ type: 'densityDropdownKey', payload: -1 });
      dispatch({ type: 'uomDensityKey', payload: -1 });
      dispatch({type: 'prevViscosityDropdownKey',payload: -1,});
      dispatch({ type: 'viscosityDropdownKey', payload: -1 });
      dispatch({ type: 'uomViscosityKey', payload: -1 });
      dispatch({type: 'prevVaporPressureDropdownKey',payload: -1,});
      dispatch({ type: 'vaporPressureDropdownKey', payload: -1 });
      dispatch({ type: 'uomVaporPressureKey', payload: -1 });
      dispatch({ type: 'oppCondition', payload: '' })

      dispatch({ type: 'prevPressureDropdownKey', payload: -1 });
      dispatch({ type: 'uomPressureKey', payload: -1 });

      dispatch({ type: 'prevTemperatureDropdownKey', payload: -1 });
      dispatch({ type: 'uomTemperatureKey', payload: -1 });

      dispatch({ type: 'prevGasDensityDropdownKey', payload: -1 });
      dispatch({ type: 'uomGasDensityKey', payload: -1 })
      
      setTimeout(() => {
        dispatch({ type: 'showVaporPressureRow', payload: true });
        dispatch({ type: 'showSonicVelocityRow', payload: false });
        dispatch({ type: 'showCompressibility', payload: false });
        dispatch({type: 'vaporPressureMin',payload: 'border-disabled'});
        dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
        dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
        dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
        dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
        dispatch({type: 'normalViscosityError', payload:'',}); 
        dispatch({type: 'minViscosityError',payload: '',});
        dispatch({type: 'maxViscosityError',payload: '',});
        //
        dispatch({ type: 'densityNormal', payload: 'border-disabled' });
        dispatch({ type: 'densityMin', payload: 'border-disabled' });
        dispatch({ type: 'densityMax', payload: 'border-disabled' });
        dispatch({type: 'minDensityError',payload: '',});
        dispatch({type: 'normalDensityError', payload: '',});
        dispatch({type: 'maxDensityError',payload: '',});
        //
        props.minViscosityConvertionError(false);
        props.normalViscosityConvertionError(false);
        props.maxViscosityConvertionError(false);
        dispatch({ type: 'densityHeadingDisabled', payload: true });
      }, 1500);
    
    }
  }, [props.clear_all_field]);

  useEffect(() => {
    if(props.allow_pref_base_ref){
      setInitialValues(props.saved_preferences);
    }
      
  }, [props.units_data, props.saved_preferences]);

  useEffect(() => {
    if (props.select_technology === 'Coriolis' && !props.fluid_selection_flag) {
      props.maxFlowAcc('3.00');
    } else if (props.select_technology === 'Density' && !props.fluid_selection_flag) {
      props.maxFlowAcc('3.00');
    } else {
      props.maxFlowAcc('');
    }
  }, [props.select_technology]);
  
  //set units in the different dropdowns 
  useEffect(() => {
    if (props.units_data) {
      dispatch({
        type: 'densityDropdownUnits',
        payload: props.units_data.density,
      });
      dispatch({
        type: 'gasDensityDropdownUnits',
        payload: props.units_data.density,
      });
      dispatch({
        type: 'gasDensitySGDropdownUnits',
        payload: props.units_data.density,
      });
      dispatch({
        type: 'viscosityDropdownUnits',
        payload: props.units_data.viscosity,
      });
      dispatch({
        type: 'vaporPressureDropdownUnits',
        payload: props.units_data.vaporPressure,
      });
      dispatch({
        type: 'maxPressureDropDropdownUnits',
        payload: props.units_data.nonVaporPressure,
      });
    }
  }, [props.units_data]);

  //cleans errors and values when fluid source 
useEffect(() => {
  
  if(props.reset_resizing_flag&&!props.fluid_selection_flag){
    if(props.select_technology.toLowerCase()==='viscosity'){
      if((props.fluid_state.toLowerCase() ==='liquid' ||props.fluid_state.toLowerCase()==='slurry')&& 
      (props.fluid_source.toLowerCase() ==='custom'|| props.fluid_source.toLowerCase() ==='database')&& resizingFlag){
        props.normalDensity('');
        props.normalDensityValue('')
        props.minDensityValue('')
        props.maxDensityValue('')
        props.minViscosityValue('')
        props.normalViscosityValue('')
        props.maxViscosityValue('')
        props.normalViscosity('');
        props.maxDensity('');
        props.maxViscosity('');
        props.minDensity('');
        props.minViscosity('')
        props.minVapor('');
        props.minVaporValue('');
        props.maxDensityMinMaxErrors(false)
        props.normalDensityMinMaxErrors(false) 
        props.normalViscosityMinMaxErrors(false)
        props.maxViscosityMinMaxErrors(false)
        props.normalDensityTypeErrors('')
        props.maxDensityTypeErrors('')
        props.normalViscosityTypeErrors('')
        props.maxViscosityTypeErrors('')

        dispatch({
          type: 'normalDensityError',
          payload: '',
        });
        dispatch({ type: 'densityNormal', payload: 'border-required' });
  
        dispatch({
          type: 'maxDensityError',
          payload: '',
        });
        dispatch({ type: 'densityMax', payload: 'border-required' });
  
        dispatch({
          type: 'normalViscosityError',
          payload: '',
        })
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
  
        if(props.units_density_heading ==='Density'){
          dispatch({
            type: 'normalViscosityError',
            payload: '',
          })
        }
      }
       
    }else if( props.select_technology.toLowerCase()==='density'){
      if(
        (props.fluid_state.toLowerCase()==='liquid'||props.fluid_state.toLowerCase()==='gas' || props.fluid_state.toLowerCase()==='slurry')&&
        (props.fluid_source.toLowerCase() ==='custom'|| props.fluid_source.toLowerCase() ==='database')&& resizingFlag
      ){
        props.normalDensity('');
        props.normalViscosity('');
        props.minVapor('');
        props.maxDensity('');
        props.maxViscosity('');
        props.sonicVelocity('')
        props.sonicVelocityValue('')
        props.normalDensityValue('')
        props.minDensityValue('')
        props.maxDensityValue('')
        props.minViscosityValue('')
        props.normalViscosityValue('')
        props.maxViscosityValue('')
        props.gasCompressibility('');
        props.densitySG('');
        props.maxDensityMinMaxErrors(false)
        props.minDensity('');
        props.minViscosity('');
        props.normalDensityMinMaxErrors(false) 
        props.normalViscosityMinMaxErrors(false)
        props.maxViscosityMinMaxErrors(false)
        props.normalDensityTypeErrors('')
        props.maxDensityTypeErrors('')
        props.normalViscosityTypeErrors('')
        props.maxViscosityTypeErrors('')
        dispatch({type: 'maxDensityError',payload: '',});
        dispatch({type: 'maxViscosityError',payload: '',});
        
      }
    }else if( props.select_technology.toLowerCase()==='coriolis' || props.select_technology.toLowerCase()==='magnetic' || props.select_technology.toLowerCase()==='vortex' ){
      if(
        (props.fluid_state.toLowerCase()==='liquid'||props.fluid_state.toLowerCase()==='gas' 
        || props.fluid_state.toLowerCase()==='slurry' ||  props.fluid_state.toLowerCase()==='steam' ) &&
        (props.fluid_source.toLowerCase() ==='custom'|| props.fluid_source.toLowerCase() ==='database')&& resizingFlag
      ){
        props.normalDensity('');
        props.normalDensityValue('')
        props.minDensityValue('')
        props.maxDensityValue('')
        props.normalViscosity('');
        props.minVapor('');
        props.maxDensity('');
        props.maxViscosity('');
        props.minDensity('');
        props.minViscosity('')
        props.minViscosityValue('')
        props.normalViscosityValue('')
        props.maxViscosityValue('')
        props.sonicVelocity('')
        props.sonicVelocityValue('')
        props.specificGravity('');
        props.molecularWeight('')
        props.minVapor('');
        props.pressureAtmospheric('');
        props.gasCompressibility('');
        props.densitySG('');
        props.gasDensity('')
        props.maxDensityMinMaxErrors(false)
        props.normalDensityMinMaxErrors(false) 
        props.normalViscosityMinMaxErrors(false)
        props.maxViscosityMinMaxErrors(false)
        props.normalDensityTypeErrors('')
        props.maxDensityTypeErrors('')
        props.normalViscosityTypeErrors('')
        props.maxViscosityTypeErrors('')
        dispatch({type: 'maxDensityError',payload: '',});
        dispatch({type: 'maxViscosityError',payload: '',});

        
      }
    }
  }
  
  setTimeout(() => {
    props.resetResizingFlag(true)
  }, 5000);
  

  
}, [props.fluid_source, props.fluid_state, props.units_density_heading])

useEffect(() => {
  if(!props.fluid_selection_flag){
  let params=props.fluid_selection_section
  params.non_newtonian_liquid = props.non_newtonian_liquid;
  params.k_flow_consistency_index = props.k_flow_consistency_index; 
  params.n_flow_behavior = props.n_flow_behavior;
  params.units_density_heading = props.units_density_heading;
  params.density_sg =  props.density_specific_gravity;
  params.compressibility = props.compressibility;
  params.gas_density = props.gas_density;
  params.max_flow_acc = props.max_flow_acc
  params.sonic_velocity = props.sonic_velocity
  params.max_pressure_drop = props.max_pressure_drop
  params.units_gas_temperature = props.units_gas_temperature
  params.units_sonic_velocity = props.units_sonic_velocity
  params.units_gas_pressure = props.units_gas_pressure
  params.units_gas_density = props.units_gas_density
  params.op_data = props.operating_conditions;
  props.fluidSelectionSection(params)

  }
}, [props.fluid_selection_flag])
useEffect(() => {

  if(props.fluid_selection_flag&&props.fluid_selection_section.op_data!==undefined){
    if(props.fluid_selection_section.non_newtonian_liquid){
      props.nonNewtonianLiquid(true)
      toggleNonNewtonLiquidHandle(props.fluid_selection_section.non_newtonian_liquid)
      props.kFlowConsistencyIndex(fixToDecimalPlaces(props.fluid_selection_section.k_flow_consistency_index, decimalPlaces, reg))
      props.nFlowBehavior(fixToDecimalPlaces(props.fluid_selection_section.n_flow_behavior, decimalPlaces, reg))
      props.nonNewtonianLiquid(props.fluid_selection_section.non_newtonian_liquid)  
    }
    dispatch({
      type: 'prevTemperatureDropdownKey',
      payload: props.units_data.temperature.findIndex(
        (p) => p.key === props.fluid_selection_section.units_gas_temperature.key
      ),
    });
    dispatch({
      type: 'temperatureDropdownKey',
      payload: props.units_data.temperature.findIndex(
        (p) => p.key === props.fluid_selection_section.units_gas_temperature.key
      ),
    });
    dispatch({
      type: 'prevSonicVelocityDropdownKey',
      payload: props.units_data.sonicVelocity.findIndex(
        (p) => p.key ===  props.fluid_selection_section.units_sonic_velocity.key
      ),
    });
    dispatch({
      type: 'SonicVelocityDropdownKey',
      payload: props.units_data.sonicVelocity.findIndex(
        (p) => p.key ===  props.fluid_selection_section.units_sonic_velocity.key
      ),
    });
    dispatch({
      type: 'prevPressureDropdownKey',
      payload: props.units_data.vaporPressure.findIndex(
        (p) => p.key === props.fluid_selection_section.units_gas_pressure.key
      ),
    });
    dispatch({
      type: 'pressureDropdownKey',
      payload: props.units_data.vaporPressure.findIndex(
        (p) => p.key === props.fluid_selection_section.units_gas_pressure.key
      ),
    });

    dispatch({
      type: 'prevGasDensityDropdownKey',
      payload: props.units_data.density.findIndex(
        (p) => p.key === props.fluid_selection_section.units_gas_density.key
      ),
    });
    
    dispatch({
      type: 'gasDensityDropdownKey',
      payload: props.units_data.density.findIndex(
        (p) => p.key === props.fluid_selection_section.units_gas_density.key
      ),
    });

    props.unitsDensityHeading(props.fluid_selection_section.units_density_heading);
    props.densitySG(fixToDecimalPlaces(props.fluid_selection_section.density_sg, decimalPlaces, reg));
    props.gasCompressibility(fixToDecimalPlaces(props.fluid_selection_section.op_data.compressibility, decimalPlaces, reg));
    props.normalDensity(fixToDecimalPlaces(props.fluid_selection_section.op_data.normal_density, decimalPlaces, reg));
    props.normalViscosity(fixToDecimalPlaces(props.fluid_selection_section.op_data.normal_viscosity, decimalPlaces, reg));
    props.minVapor(fixToDecimalPlaces(props.fluid_selection_section.op_data.min_vapor, decimalPlaces, reg));
    props.minDensity(fixToDecimalPlaces(props.fluid_selection_section.op_data.min_density, decimalPlaces, reg));
    props.maxDensity(fixToDecimalPlaces(props.fluid_selection_section.op_data.max_density, decimalPlaces, reg));
    props.specificGravity(fixToDecimalPlaces(props.fluid_selection_section.op_data.specific_gravity, decimalPlaces, reg));
    props.molecularWeight(fixToDecimalPlaces(props.fluid_selection_section.op_data.molecular_weight, decimalPlaces, reg));
    props.minViscosity(fixToDecimalPlaces(props.fluid_selection_section.op_data.min_viscosity, decimalPlaces, reg));
    props.normalViscosity(fixToDecimalPlaces(props.fluid_selection_section.op_data.normal_viscosity, decimalPlaces, reg));
    props.maxViscosity(fixToDecimalPlaces(props.fluid_selection_section.op_data.max_viscosity, decimalPlaces, reg));
    props.pressureAtmospheric(fixToDecimalPlaces(props.fluid_selection_section.op_data.pressure_atmospheric, decimalPlaces, reg));
    props.maxFlowAcc(fixToDecimalPlaces(props.fluid_selection_section.max_flow_acc, decimalPlaces, reg));
    props.maxPressureDrop(fixToDecimalPlaces(props.fluid_selection_section.max_pressure_drop, decimalPlaces, reg));
    
    if(!props.saved_preferences_flag){
      props.gasPressure(fixToDecimalPlaces(props.fluid_selection_section.op_data.gas_pressure, decimalPlaces, reg));
      props.gasTemperature(fixToDecimalPlaces(props.fluid_selection_section.op_data.gas_temperature, decimalPlaces, reg));
      props.gasDensity(fixToDecimalPlaces(props.fluid_selection_section.gas_density, decimalPlaces, reg));
    }

    props.baseCompressibility(fixToDecimalPlaces(props.fluid_selection_section.op_data.base_compressibility, decimalPlaces, reg));
    props.sonicVelocity(fixToDecimalPlaces(props.fluid_selection_section.sonic_velocity, decimalPlaces, reg));

  }
}, [props.fluid_selection_flag])

useEffect(() => {
  if((props.fluid_state.toLowerCase()==='gas'||props.fluid_state.toLowerCase()==='slurry'|| props.fluid_state.toLowerCase()==='liquid' || props.fluid_state.toLowerCase()==='steam' ) &&
  (props.fluid_source.toLowerCase() ==='custom'||props.fluid_source.toLowerCase() ==='database')&& !props.fluid_selection_flag && resizingFlag){
      props.unitsDensityHeading('Density');
      dispatch({ type: 'uomObjectArray', payload: []});
    }else if(props.fluid_state.toLowerCase()==='gas' && 
    props.fluid_source === CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props)&& !props.fluid_selection_flag && resizingFlag){
      props.unitsDensityHeading('Density');
    }
}, [props.fluid_state, props.fluid_source])


  // TODO: need to do something about this monster if-else statement!
const  Coriolis = () =>{
  dispatch({ type: 'selectedTechnology',payload: props.select_technology,});
  dispatch({ type: 'showViscosityRow', payload: true });
  props.gasTemperatureError(false)
  props.gasPressureError(false)
  
  if (props.fluid_state.toLowerCase() === 'liquid') {
    props.baseReferenceConditions(false);
    dispatch({type: 'showBaseCompressibilityRow', payload:false});
    dispatch({type: 'showDensityRow', payload:false});
    dispatch({ type: 'showSonicVelocityRow', payload: false });
    dispatch({ type: 'specificGravityError', payload: '' });
    dispatch({ type: 'specificGravity', payload: 'border-required' });
    dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
    dispatch({ type: 'kFlowConsistencyIndexError', payload: '' });
    dispatch({ type: 'nFlowBehaviorError', payload: '' });
    dispatch({ type: 'normalDensityError', payload: '' });
    dispatch({ type: 'normalViscosityError', payload: '' });
    dispatch({ type: 'showVaporPressureRow', payload: true });
    dispatch({ type: 'showMaxFlowAccuracyRow', payload: true });
    dispatch({ type: 'showMaxPressureDropRow', payload: true });
    dispatch({ type: 'showPressureAtmosphericRow', payload: false });
    dispatch({ type: 'densityNormal', payload: 'border-disabled' });
    dispatch({ type: 'densityMin', payload: 'border-disabled' });
    dispatch({ type: 'densityMax', payload: 'border-disabled' });
    dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
    dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
    dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
    dispatch({ type: 'vaporPressureMin', payload: 'border-disabled' });
    dispatch({type: 'baseGasDensityHeadingDisabled', payload: false})
    if (props.fluid_source.toLowerCase() === 'custom') {
      dispatch({ type: 'densityMin', payload: 'border-not-required' });
      dispatch({ type: 'showCompressibility', payload: false });
      if (state.normalDensityError !== '') {
        dispatch({
          type: 'normalDensityError',
          payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
        });
        dispatch({ type: 'densityNormal', payload: 'border-error' });
      } else {
        dispatch({ type: 'densityNormal', payload: 'border-required' });
      }
      dispatch({ type: 'densityMax', payload: 'border-not-required' });
      dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
      
      

      
     
      dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
      dispatch({type: 'vaporPressureMin',payload: 'border-not-required'});
      dispatch({ type: 'showNonNewtonianCheckbox', payload: true });
      dispatch({ type: 'densityHeadingDisabled', payload: false }); 
      
      if(props.units_density_heading === 'Density'){
        dispatch({ type: 'normalViscosityError', payload: '' });
        dispatch({ type: 'normalDensityError', payload: '' });
        dispatch({type:'specificGravityError', payload:''})
        dispatch({type:'specificGravity', payload:'border-required'})
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        dispatch({ type: 'densityNormal', payload: 'border-required' });
      }else if(props.units_density_heading === 'Molecular Weight' || props.units_density_heading === 'Specific Gravity'){
        //show density row 
        dispatch({ type: 'normalViscosityError', payload: '' });
        dispatch({ type: 'normalDensityError', payload: '' });
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        dispatch({type:'specificGravityError', payload:''})
        dispatch({type:'specificGravity', payload:'border-required'})
        dispatch({ type: 'densityNormal', payload: 'border-required' });
      }

      if (props.non_newtonian_liquid) {
        dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
        dispatch({type: 'viscosityNormal',payload: 'border-disabled',});
        dispatch({type: 'viscosityMax',payload: 'border-disabled' ,});
      } else {
        if (state.normalViscosityError !== '') {
          dispatch({type: 'normalViscosityError',
            payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'viscosityNormal', payload: 'border-error' });
        } else {
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        }
        dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
        dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
        if (state.kFlowConsistencyIndexError !== '') {
          dispatch({
            type: 'kFlowConsistencyIndexError',
            payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({type: 'kFlowConsistencyIndex',payload: 'border-error',});
        } else {
          dispatch({type: 'kFlowConsistencyIndex',payload: 'border-required',});
          dispatch({ type: 'kFlowConsistencyIndexError', payload: '' });
        }
        if (state.nFlowBehaviorError !== '') {
          dispatch({
            type: 'nFlowBehaviorError',
            payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'nFlowBehaviorError', payload: 'border-error' });
        } else {
          dispatch({type: 'nFlowBehaviorError',payload: 'border-required'});
          dispatch({ type: 'nFlowBehaviorError', payload: '' });
        }
      }

    } else if (props.fluid_source.toLowerCase() === 'database') {
      dispatch({ type: 'densityHeadingDisabled', payload: true });
      dispatch({ type: 'showCompressibility', payload: false });
      props.nonNewtonianLiquid(false);
      dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
      dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });     
      
      dispatch({
        type: 'minViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
      props.minViscosityConvertionError(false)
      dispatch({
        type: 'normalViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
      props.normalViscosityConvertionError(false)

      dispatch({
        type: 'maxViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
      props.maxViscosityConvertionError(false)

    }
  } else if (props.fluid_state.toLowerCase() === 'gas') {
    dispatch({ type: 'gasTemperature', payload: 'border-required' });
    dispatch({type: 'gasTemperatureError',payload: ''});
    props.nonNewtonianLiquid(false);
    dispatch({ type: 'showVaporPressureRow', payload: false });
    dispatch({ type: 'kFlowConsistencyIndexError', payload: false });
    dispatch({ type: 'nFlowBehaviorError', payload: false });
    dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
    dispatch({ type: 'showMaxFlowAccuracyRow', payload: true });
    dispatch({ type: 'showMaxPressureDropRow', payload: true });
    dispatch({ type: 'showSonicVelocityRow', payload: true });
    dispatch({ type: 'densityNormal', payload: 'border-disabled' });
    dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
    dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
    dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
    dispatch({ type: 'normalDensityError', payload: '' });
    dispatch({ type: 'normalViscosityError', payload: '' });
    dispatch({ type: 'kFlowConsistencyIndexError', payload: '' });
    dispatch({ type: 'nFlowBehaviorError', payload: '' });
    dispatch({type: 'showBaseCompressibilityRow', payload:false});
    dispatch({type: 'baseGasDensityHeadingDisabled', payload: false})
    dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
    dispatch({ type: 'gasPressure', payload: 'border-required' });
    dispatch({ type: 'gasPressureError',payload: '',});
    if (props.fluid_source === CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props)) {
      dispatch({type:'velocitySonic',payload: 'border-disabled'})
      dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
      dispatch({ type: 'viscosityNormal', payload: 'border-required' });
      dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
      dispatch({ type: 'showCompressibility', payload: false });
      dispatch({type: 'showDensityRow', payload:false});
      dispatch({type: 'showBaseCompressibilityRow', payload:false});
      dispatch({ type: 'densityMin', payload: 'border-disabled' });
      dispatch({ type: 'densityNormal', payload: 'border-disabled' });
      dispatch({ type: 'densityMax', payload: 'border-disabled' });

    } else if (props.fluid_source.toLowerCase() === 'custom') {
      dispatch({type:'velocitySonic',payload: 'border-not-required'})
      dispatch({ type: 'showVaporPressureRow', payload: false });
      props.nonNewtonianLiquid(false);
      dispatch({ type: 'densityMin', payload: 'border-not-required' });
      dispatch({ type: 'densityNormal', payload: 'border-required' });
      props.normalDensityError(false)
      dispatch({ type: 'densityMax', payload: 'border-not-required' });
      dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
      dispatch({ type: 'viscosityNormal', payload: 'border-required' });
      props.normalViscosityError(false)
      dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
      dispatch({ type: 'gasCompressibility', payload: 'border-disabled' });
      dispatch({ type: 'gasDensity', payload: 'border-required' });
      props.gasDensityError(false)
      dispatch({ type: 'gasDensityError', payload: '' });
      dispatch({ type: 'densityHeadingDisabled', payload: false });
      dispatch({ type: 'kFlowConsistencyIndexError', payload: false });
      dispatch({ type: 'nFlowBehaviorError', payload: false });
        if(props.units_density_heading === 'Density'){
          dispatch({ type: 'gasCompressibility', payload: 'border-disabled' });
          dispatch({type: 'gasCustomCompressibility', payload:'border-disabled'});
          dispatch({type: 'showDensityRow', payload:false});
          dispatch({type:'specificGravityError', payload:''});
          dispatch({type:'specificGravity', payload:'border-required'});
          dispatch({ type: 'showCompressibility', payload: false });
          dispatch({type:'molecularWeightError', payload:''});
        dispatch({type:'molecularWeight', payload:'border-required'});
        dispatch({type: 'showBaseCompressibilityRow', payload:false});
        dispatch({type: 'baseGasDensityHeadingDisabled', payload: false})
        }else if(props.units_density_heading === 'Molecular Weight' || props.units_density_heading === 'Specific Gravity'){
          dispatch({type: 'gasCustomCompressibility', payload:'border-required'});
          dispatch({ type: 'gasCompressibility', payload: 'border-required' });
          dispatch({ type: 'gasDensity', payload: 'border-disabled' });
          //show density row 
          dispatch({type: 'showDensityRow', payload:true});
          dispatch({type: 'showBaseCompressibilityRow', payload:true});
          dispatch({ type: 'showCompressibility', payload: true });
          dispatch({type:'specificGravityError', payload:''})
          dispatch({type:'specificGravity', payload:'border-required'})
          dispatch({type:'molecularWeightError', payload:''})
          dispatch({type:'molecularWeight', payload:'border-required'})
          dispatch({type: 'gasDensityError',payload: ''});
          dispatch({type: 'baseGasDensityHeadingDisabled', payload: false})
        }
    } else if (props.fluid_source.toLowerCase() === 'database') {
      dispatch({type:'velocitySonic',payload: 'border-disabled'})
      dispatch({ type: 'densityHeadingDisabled', payload: true });
      dispatch({ type: 'showCompressibility', payload: false });
      dispatch({ type: 'gasDensity', payload: 'border-disabled' });
      dispatch({type: 'showDensityRow', payload:false});
      dispatch({type: 'showBaseCompressibilityRow', payload:false});
      dispatch({ type: 'densityMin', payload: 'border-disabled' });
      dispatch({ type: 'densityNormal', payload: 'border-disabled' });
      dispatch({ type: 'densityMax', payload: 'border-disabled' });
      dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });  
      dispatch({
        type: 'minViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
      props.minViscosityConvertionError(false)
      dispatch({
        type: 'normalViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
      props.normalViscosityConvertionError(false)

      dispatch({
        type: 'maxViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
      props.maxViscosityConvertionError(false)
    }
    if (props.calculate_density_variation) { 
      if (state.maxDensityError !== '') {
        dispatch({
          type: 'maxDensityError',
          payload: [
            CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG20', props),
            CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props),
            CustomTranslationText('XXSPECIFY_MMI_MC_TXT_INPUT', props),
          ],
        });
        dispatch({ type: 'densityMax', payload: 'border-error' });
      } else {
        dispatch({ type: 'maxDensityError', payload: '' });
        dispatch({ type: 'densityMax', payload: 'border-disabled' });
      }
      if (state.minDensityError !== '') {
        dispatch({
          type: 'minDensityError',
          payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
        });
        dispatch({ type: 'densityMin', payload: 'border-error' });
      } else {
        dispatch({ type: 'minDensityError', payload: '' });
        dispatch({ type: 'densityMin', payload: 'border-disabled' });
      }
      if (props.fluid_source.toLowerCase() === 'custom') {
        dispatch({ type: 'densityMin', payload: 'border-required' });
        dispatch({ type: 'densityMax', payload: 'border-required' });
        dispatch({ type: 'gasDensity', payload: 'border-required' });
        dispatch({ type: 'densityNormal', payload: 'border-required' });
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
      }


    } else {
      dispatch({ type: 'maxDensityError', payload: '' });
      dispatch({ type: 'minDensityError', payload: '' });
    }
  } else {
    // slurry && custom
    props.baseReferenceConditions(false);
    dispatch({ type: 'showSonicVelocityRow', payload: false });
    dispatch({ type: 'specificGravityError', payload: '' });
    dispatch({ type: 'normalDensityError', payload: '' });
    dispatch({ type: 'densityMin', payload: 'border-disabled' });
    dispatch({ type: 'densityNormal', payload: 'border-required'});
    dispatch({ type: 'densityMax', payload: 'border-disabled' });
    dispatch({ type: 'maxDensityError', payload: '' });
    dispatch({ type: 'minDensityError', payload: '' });
    dispatch({ type: 'specificGravity', payload: 'border-required' });
    dispatch({ type: 'densityHeadingDisabled', payload: false });
    dispatch({ type: 'showNonNewtonianCheckbox', payload: true });
    dispatch({ type: 'showVaporPressureRow', payload: true });
    dispatch({ type: 'showMaxFlowAccuracyRow', payload: true });
    dispatch({ type: 'showMaxPressureDropRow', payload: true });
    dispatch({ type: 'normalViscosityValue', payload: '' });
    dispatch({ type: 'kFlowConsistencyIndex', payload: 'border-required' });
    dispatch({ type: 'nFlowBehavior', payload: 'border-required' });
    dispatch({ type: 'densityMin', payload: 'border-not-required' });
    dispatch({ type: 'showCompressibility', payload: false });
    dispatch({type: 'showDensityRow', payload:false});

    if (state.normalDensityError !== '') {
      dispatch({ type: 'densityNormal', payload: 'border-error' });
    } else {
      dispatch({ type: 'densityNormal', payload: 'border-required' });
    }

    dispatch({ type: 'densityMax', payload: 'border-not-required' });
    dispatch({ type: 'viscosityMin', payload: 'border-not-required' });

    if (state.normalViscosityError !== '') {
      if (props.non_newtonian_liquid) {
        dispatch({
          type: 'viscosityNormal',
          payload: 'border-not-required',
        });
      } else {
        dispatch({ type: 'viscosityNormal', payload: 'border-error' });
      }
    } else {
      if (props.non_newtonian_liquid) {
        dispatch({
          type: 'viscosityNormal',
          payload: 'border-not-required',
        });
      } else {
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
      }
    }
    dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
    dispatch({ type: 'vaporPressureMin', payload: 'border-not-required' });

    if(props.units_density_heading === 'Density'){
      dispatch({type: 'gasCustomCompressibility', payload:'border-disabled'});
      dispatch({type: 'showDensityRow', payload:false});
      dispatch({ type: 'normalViscosityError', payload: '' });
      dispatch({ type: 'viscosityNormal', payload: 'border-required' });
      dispatch({type:'specificGravityError', payload:''})
      dispatch({type:'specificGravity', payload:'border-required'})
      dispatch({type:'molecularWeightError', payload:''})
      dispatch({type:'molecularWeight', payload:'border-required'})
    }else if(props.units_density_heading === 'Molecular Weight' || props.units_density_heading === 'Specific Gravity'){
      dispatch({type: 'gasCustomCompressibility', payload:'border-required'});
      //show density row 
      dispatch({type: 'showDensityRow', payload:false});
      dispatch({type:'specificGravityError', payload:''})
      dispatch({type:'specificGravity', payload:'border-required'})
      dispatch({type:'molecularWeightError', payload:''})
      dispatch({type:'molecularWeight', payload:'border-required'})
      dispatch({ type: 'normalViscosityError', payload: '' });
      dispatch({ type: 'viscosityNormal', payload: 'border-required' });
    }


  }
}

const  Vortex = () =>{

    dispatch({ 
     type: 'normalDensityError',
      payload: '',
    });
    dispatch({ type: 'densityMin', payload: 'border-disabled' });
    dispatch({ type: 'densityMax', payload: 'border-disabled' });
    dispatch({ type: 'maxDensityError', payload: '' });
    dispatch({ type: 'minDensityError', payload: '' });
    dispatch({
      type: 'selectedTechnology',
      payload: props.select_technology,
    });
    dispatch({ type: 'showSonicVelocityRow', payload: false });
    dispatch({ type: 'showViscosityRow', payload: true });
    dispatch({ type: 'showPressureAtmosphericRow', payload: true });
    dispatch({ type: 'atmosphericPressure', payload: 'border-not-required' });
    dispatch({ type: 'showMaxPressureDropRow', payload: false });
    dispatch({ type: 'showMaxFlowAccuracyRow', payload: false });
    dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
    props.gasTemperatureError(false)
    props.gasPressureError(false)
    if (props.fluid_state.toLowerCase() === 'liquid') {
      dispatch({ type: 'showVaporPressureRow', payload: true });
      dispatch({type: 'showBaseCompressibilityRow', payload:false});
      dispatch({type: 'showDensityRow', payload:false});
      dispatch({ type: 'showCompressibility', payload: false });
      if (props.fluid_source.toLowerCase() === 'database') {
        
        dispatch({type: 'showBaseCompressibilityRow', payload:false});
        props.baseReferenceConditions(false);
        dispatch({type: 'showDensityRow', payload:false});
        dispatch({ type: 'showCompressibility', payload: false });
        
        dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
        props.nonNewtonianLiquid(false);
        dispatch({ type: 'densityHeadingDisabled', payload: true });
        dispatch({ type: 'densityNormal', payload: 'border-disabled' });
        dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
        dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
        dispatch({type: 'vaporPressureMin',payload: 'border-not-required',});
        dispatch({ type: 'normalViscosityError', payload: '' });
        dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });  
        dispatch({
          type: 'minViscosityError',
          payload: '',
        });
        dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
        props.minViscosityConvertionError(false)
        dispatch({
          type: 'normalViscosityError',
          payload: '',
        });
        dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
        props.normalViscosityConvertionError(false)
  
        dispatch({
          type: 'maxViscosityError',
          payload: '',
        });
        dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
        props.maxViscosityConvertionError(false)


      } else if (props.fluid_source.toLowerCase() === 'custom') {
        dispatch({ type: 'densityHeadingDisabled', payload: false });
        dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
        dispatch({ type: 'densityNormal', payload: 'border-required' });
        dispatch({ type: 'densityMax', payload: 'border-not-required' });
        dispatch({ type: 'densityMin', payload: 'border-not-required' });
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
        dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
        dispatch({type: 'showBaseCompressibilityRow', payload:false});
        dispatch({ type: 'showCompressibility', payload: false });
        if(props.units_density_heading === 'Density'){
          dispatch({ type: 'normalViscosityError', payload: '' });
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
          dispatch({type:'specificGravityError', payload:''})
          dispatch({type:'specificGravity', payload:'border-required'})
          dispatch({type: 'baseGasDensityHeadingDisabled', payload: false})
        }else if(props.units_density_heading === 'Molecular Weight' || props.units_density_heading === 'Specific Gravity'){
          dispatch({type: 'gasCustomCompressibility', payload:'border-required'});
          dispatch({type:'specificGravityError', payload:''})
          dispatch({type:'specificGravity', payload:'border-required'})
          dispatch({ type: 'normalViscosityError', payload: '' });
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
          dispatch({type: 'baseGasDensityHeadingDisabled', payload: false})
        }
  
      } else {
        props.baseReferenceConditions(false);
        dispatch({ type: 'showCompressibility', payload: false });
        dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
      }
    } else if (props.fluid_state.toLowerCase() === 'gas') {
      dispatch({ type: 'gasTemperature', payload: 'border-required' });
      dispatch({type: 'gasTemperatureError',payload: ''});
      dispatch({ type: 'gasPressure', payload: 'border-required' });
      dispatch({type: 'gasPressureError',payload: ''});
      dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
      dispatch({ type: 'showVaporPressureRow', payload: false });
      props.nonNewtonianLiquid(false);
        if(props.fluid_source.toLowerCase()==='database'){
          dispatch({type: 'showBaseCompressibilityRow', payload:false});
          dispatch({ type: 'densityHeadingDisabled', payload: true });
          dispatch({type: 'showDensityRow', payload:false});
          dispatch({ type: 'showCompressibility', payload: false });
          dispatch({ type: 'densityNormal', payload: 'border-disabled' });
          dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
          
          dispatch({
            type: 'normalViscosityError',
            payload:'',
          });

          dispatch({
            type: 'minViscosityError',
            payload: '',
          });
          dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
          props.minViscosityConvertionError(false)
          dispatch({
            type: 'normalViscosityError',
            payload: '',
          });
          dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
          props.normalViscosityConvertionError(false)
    
          dispatch({
            type: 'maxViscosityError',
            payload: '',
          });
          dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
          props.maxViscosityConvertionError(false)


        }else if (props.fluid_source ===CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props)) {
        if (state.normalViscosityError !== '') {
          dispatch({
            type: 'normalViscosityError',
            payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'viscosityNormal', payload: 'border-error' });
        } else {
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        }
        dispatch({ type: 'showCompressibility', payload: false });
        dispatch({type: 'showBaseCompressibilityRow', payload:false});
        dispatch({type: 'showDensityRow', payload:false});
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        dispatch({ type: 'densityNormal', payload: 'border-disabled' });
        dispatch({ type: 'densityMin', payload: 'border-disabled' });
        dispatch({ type: 'densityMax', payload: 'border-disabled' });
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
      } else if (props.fluid_source.toLowerCase() === 'custom') {
        props.nonNewtonianLiquid(false);
        dispatch({ type: 'showVaporPressureRow', payload: false });
        dispatch({ type: 'densityHeadingDisabled', payload: false });
        dispatch({ type: 'densityNormal', payload: 'border-required' });
        props.normalDensityError(false)
        dispatch({ type: 'gasDensity', payload: 'border-disabled' });
        dispatch({type: 'showBaseCompressibilityRow', payload:false});
        if (state.normalViscosityError !== '') {
          dispatch({
            type: 'normalViscosityError',
            payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'viscosityNormal', payload: 'border-error' });
        } else {
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        }
        
        dispatch({ type: 'densityMin', payload: 'border-not-required' });
        dispatch({ type: 'densityMax', payload: 'border-not-required' });
        dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
        dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
        dispatch({type: 'gasCustomCompressibility', payload:'border-disabled'});
        if(props.units_density_heading === 'Density'){
          dispatch({ type: 'showCompressibility', payload: false });
          dispatch({type: 'showBaseCompressibilityRow', payload:false});
          dispatch({ type: 'gasDensity', payload: 'border-required' });
          props.gasDensityError(false)
          dispatch({ type: 'gasDensityError', payload: '' });
          dispatch({type: 'gasCustomCompressibility', payload:'border-disabled'});
          dispatch({type: 'showDensityRow', payload:false});
          dispatch({ type: 'normalViscosityError', payload: '' });
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
          props.normalViscosityError(false)
          dispatch({type:'specificGravityError', payload:''})
          dispatch({type:'specificGravity', payload:'border-required'})
          dispatch({type:'molecularWeightError', payload:''})
          dispatch({type:'molecularWeight', payload:'border-required'})
          dispatch({type: 'baseGasDensityHeadingDisabled', payload: false})
        }else if(props.units_density_heading === 'Molecular Weight' || props.units_density_heading === 'Specific Gravity'){
          dispatch({ type: 'showCompressibility', payload: true });
          dispatch({type: 'gasCustomCompressibility', payload:'border-required'});
          dispatch({ type: 'gasDensity', payload: 'border-disabled' });
          dispatch({type: 'showBaseCompressibilityRow', payload:true});
          //show density row 
          dispatch({type: 'showDensityRow', payload:true});
          dispatch({ type: 'normalViscosityError', payload: '' });
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
          dispatch({type:'specificGravityError', payload:''})
          dispatch({type:'specificGravity', payload:'border-required'})
          dispatch({type:'molecularWeightError', payload:''})
          dispatch({type:'molecularWeight', payload:'border-required'})
          dispatch({type: 'gasDensityError',payload: ''});
          dispatch({type: 'baseGasDensityHeadingDisabled', payload: false})
            }
      }
    } else if (props.fluid_state.toLowerCase() === 'steam') {
      props.baseReferenceConditions(false)
      
      dispatch({ type: 'densityHeadingDisabled', payload: true });
      dispatch({type: 'showDensityRow', payload:false});
      dispatch({ type: 'showCompressibility', payload: false });
      dispatch({ type: 'normalDensityError', payload: '' });
      dispatch({ type: 'normalViscosityError', payload: '' });
      dispatch({ type: 'kFlowConsistencyIndexError', payload: '' });
      dispatch({ type: 'nFlowBehaviorError', payload: '' });
      dispatch({ type: 'densityNormal', payload: 'border-disabled' });
      dispatch({ type: 'densityMin', payload: 'border-disabled' });
      dispatch({ type: 'densityMax', payload: 'border-disabled' });
      dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
      dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
      dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
      dispatch({ type: 'showVaporPressureRow', payload: false });
      props.nonNewtonianLiquid(false);
      dispatch({ type: 'showViscosityRow', payload: true });
      dispatch({ type: 'showPressureAtmosphericRow', payload: true });
      dispatch({ type: 'atmosphericPressure', payload: 'border-not-required' });
    }
  
}
const  Magnetic = () =>{
  dispatch({ type: 'densityMin', payload: 'border-disabled' });
  dispatch({ type: 'densityMax', payload: 'border-disabled' });
  dispatch({ type: 'maxDensityError', payload: '' });
  dispatch({ type: 'minDensityError', payload: '' });
  dispatch({type: 'showDensityRow', payload:false});
  dispatch({
    type: 'selectedTechnology',
    payload: props.select_technology,
  });
  props.baseReferenceConditions(false);
  dispatch({ type: 'showCompressibility', payload: false });
  dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
  props.nonNewtonianLiquid(false);
  dispatch({ type: 'showViscosityRow', payload: false });
  dispatch({ type: 'showPressureAtmosphericRow', payload: false });
  dispatch({ type: 'showVaporPressureRow', payload: false });
  dispatch({ type: 'showMaxFlowAccuracyRow', payload: false });
  dispatch({ type: 'showMaxPressureDropRow', payload: false });
  dispatch({ type: 'showMinAccuracyRow', payload: false });
  dispatch({ type: 'showSonicVelocityRow', payload: false });
  dispatch({ type: 'densityHeadingDisabled', payload: true });
  dispatch({ type: 'densityNormal', payload: 'border-disabled'});
  dispatch({ type: 'normalDensityError', payload: '' });
  if (props.fluid_state.toLowerCase() === 'liquid') {
    dispatch({ type: 'densityMin', payload: 'border-disabled' });
    dispatch({ type: 'densityMax', payload: 'border-disabled' });
    if (props.fluid_source.toLowerCase() === 'custom') {
      dispatch({ type: 'densityNormal', payload: 'border-required' });
      dispatch({ type: 'densityMax', payload: 'border-not-required' });
      dispatch({ type: 'densityMin', payload: 'border-not-required' });
      dispatch({ type: 'densityHeadingDisabled', payload: false });

      if(props.units_density_heading === 'Density'){
        dispatch({type:'specificGravityError', payload:''})
        dispatch({type:'specificGravity', payload:'border-required'})
        dispatch({ type: 'densityNormal', payload: 'border-required' });
        dispatch({ type: 'normalDensityError', payload: '' });
      }else if(props.units_density_heading === 'Specific Gravity'){
        dispatch({ type: 'densityNormal', payload: 'border-required' });
        dispatch({type:'specificGravityError', payload:''})
        dispatch({type:'specificGravity', payload:'border-required'})
        dispatch({ type: 'normalDensityError', payload: '' });
      }

    }else if(props.fluid_state.toLowerCase()==='database'){
      dispatch({ type: 'densityHeadingDisabled', payload: true });
      dispatch({ type: 'densityNormal', payload: 'border-disabled'});
      dispatch({ type: 'normalDensityError', payload: '' });
    }
  } else if (props.fluid_state.toLowerCase() === 'slurry') {
    dispatch({ type: 'densityMin', payload: 'border-not-required' });
    dispatch({ type: 'densityNormal', payload: 'border-required' });
    dispatch({ type: 'densityMax', payload: 'border-not-required' });
    dispatch({ type: 'densityHeadingDisabled', payload: false });

    if(props.units_density_heading === 'Density'){
      dispatch({type:'specificGravityError', payload:''})
      dispatch({type:'specificGravity', payload:'border-required'})
      dispatch({ type: 'densityNormal', payload: 'border-required' });
      dispatch({ type: 'normalDensityError', payload: '' });
    }else if(props.units_density_heading === 'Specific Gravity'){
      dispatch({ type: 'densityNormal', payload: 'border-required' });
      dispatch({type:'specificGravityError', payload:''})
      dispatch({type:'specificGravity', payload:'border-required'})
      dispatch({ type: 'normalDensityError', payload: '' });
    }
  }
}
const Density =()=>{
  dispatch({ type: 'densityMin', payload: 'border-disabled' });
  dispatch({ type: 'densityNormal', payload: 'border-disabled' });
  dispatch({ type: 'densityMax', payload: 'border-disabled' });
  dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
  dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
  dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
  dispatch({ type: 'vaporPressureMin', payload: 'border-disabled' });
  dispatch({ type: 'showMaxFlowAccuracyRow', payload: false });
  if (props.select_technology.toLowerCase() === 'density' && props.tank === false){
    dispatch({ type: 'showMaxPressureDropRow', payload: true });
  }else {
    dispatch({ type: 'showMaxPressureDropRow', payload: false });
  }
  dispatch({ type: 'maxDensityError', payload: '' });
  dispatch({ type: 'minDensityError', payload: '' });
  dispatch({type: 'showBaseCompressibilityRow', payload:false});
  dispatch({ type: 'showPressureAtmosphericRow', payload: false });
  dispatch({
    type: 'selectedTechnology',
    payload: props.select_technology,
  });
  dispatch({ type: 'showViscosityRow', payload: true });
  dispatch({ type: 'showVaporPressureRow', payload: true });
  if (props.fluid_state.toLowerCase() === 'liquid') {
    dispatch({ type: 'showSonicVelocityRow', payload: false });
    dispatch({type: 'showDensityRow', payload:false});
    dispatch({ type: 'showCompressibility', payload: false });
    dispatch({ type: 'showViscosityRow', payload: true });
    dispatch({ type: 'showVaporPressureRow', payload: true });
    dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
    dispatch({type:'specificGravity', payload:'border-disabled'})
    if (props.fluid_source.toLowerCase() === 'custom') {
      dispatch({ type: 'viscosityNormal', payload: 'border-required' });
      dispatch({ type: 'densityHeadingDisabled', payload: false });
      dispatch({ type: 'showNonNewtonianCheckbox', payload: true });
      dispatch({ type: 'densityMin', payload: 'border-not-required' });
      dispatch({ type: 'densityNormal', payload: 'border-required' });
      dispatch({ type: 'densityMax', payload: 'border-required' });
      dispatch({ type: 'viscosityMax', payload: 'border-required' });
      dispatch({ type: 'normalViscosityError', payload: '' });
      dispatch({type:'specificGravity', payload:'border-required'})
      dispatch({
        type: 'vaporPressureMin',
        payload: 'border-not-required',
      });
      
      if(props.units_density_heading === 'Density'){
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        dispatch({ type: 'normalViscosityError', payload: '' });
        dispatch({ type: 'normalDensityError', payload: '' });
        
      }else if(props.units_density_heading === 'Molecular Weight' || props.units_density_heading === 'Specific Gravity'){
        //show density row 
        dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        dispatch({ type: 'normalViscosityError', payload: '' });
        dispatch({type:'specificGravityError', payload:''})
        dispatch({type:'specificGravity', payload:'border-required'})
        dispatch({ type: 'normalDensityError', payload: '' });
      }

      if (props.non_newtonian_liquid) {
        dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
        dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
        dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
        dispatch({
          type: 'kFlowConsistencyIndex',
          payload: 'border-required',
        });
        dispatch({ type: 'nFlowBehavior', payload: 'border-required' });
      } else {
        dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
        if (state.normalViscosityError !== '') {
          dispatch({
            type: 'normalViscosityError',
            payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
          });
          dispatch({ type: 'viscosityNormal', payload: 'border-error' });
        } else {
          dispatch({ type: 'viscosityNormal', payload: 'border-required' });
        }
      }


    }else if(props.fluid_source.toLowerCase() === 'database'){
      dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
      dispatch({ type: 'densityHeadingDisabled', payload: true });
      props.nonNewtonianLiquid(false)
      dispatch({
        type: 'minViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
      props.minViscosityConvertionError(false)
      dispatch({
        type: 'normalViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
      props.normalViscosityConvertionError(false)

      dispatch({
        type: 'maxViscosityError',
        payload: '',
      });
      dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
      props.maxViscosityConvertionError(false)
    }
  } else if (props.fluid_state.toLowerCase() === 'gas') {
    dispatch({
      type: 'showViscosityRow',
      payload: props.select_technology === 'Viscosity',
    });
    dispatch({ type: 'showMaxPressureDropRow', payload: false });
    props.nonNewtonianLiquid(false);
    dispatch({ type: 'showNonNewtonianCheckbox', payload: false });
    dispatch({ type: 'densityHeadingDisabled', payload: false });
    dispatch({ type: 'showSonicVelocityRow', payload: true });
    dispatch({ type: 'showVaporPressureRow', payload: false });
    dispatch({ type: 'gasPressure', payload: 'border-required' });
    dispatch({ type: 'gasPressureError',payload: '',});
    dispatch({ type: 'gasTemperature', payload: 'border-required' });
    dispatch({type: 'gasTemperatureError',payload: '',
    });
    dispatch({ type: 'densityHeadingDisabled', payload: true });
    dispatch({ type: 'normalDensityError', payload: '' });
    dispatch({type: 'showDensityRow', payload:false});
    dispatch({ type: 'showCompressibility', payload: false });
    dispatch({type:'velocitySonic',payload: 'border-disabled'})
    if (props.fluid_source.toLowerCase() === 'custom') {
      dispatch({type:'velocitySonic',payload: 'border-not-required'})
      dispatch({ type: 'densityHeadingDisabled', payload: false });
      dispatch({type: 'showDensityRow', payload:true});
      dispatch({ type: 'densityMin', payload: 'border-required' });
      dispatch({ type: 'densityNormal', payload: 'border-required' });   
      dispatch({ type: 'densityMax', payload: 'border-required' });
      dispatch({ type: 'showCompressibility', payload: true });
      dispatch({ type: 'showMaxPressureDropRow', payload: false });
      dispatch({ type: 'showMaxFlowAccuracyRow', payload: false });
      dispatch({ type: 'gasDensity', payload: 'border-disabled' });
      dispatch({ type: 'gasPressure', payload: 'border-disabled' });
      dispatch({ type: 'gasPressureError',payload: '',});
      dispatch({ type: 'gasTemperature', payload: 'border-disabled' });
      dispatch({type: 'gasTemperatureError',payload: '',});
      props.gasPressure(props.saved_preferences.gasonly_pressure,);
      props.gasTemperature(props.saved_preferences.gasonly_temperature);
      props.unitsGasPressure(getUnitsKeyValue(
        props.saved_preferences.gasonly_pressure_unit,
        'vaporPressure',
        props.units_data
      ),)
      props.unitsGasTemperature( getUnitsKeyValue(
        props.saved_preferences.gasonly_temperature_unit,
        'temperature',
        props.units_data
      ));
      props.unitsGasDensity( getUnitsKeyValue(
        props.saved_preferences.gasonly_density_unit,
        'density',
        props.units_data
      ));

      if(props.units_density_heading === 'Density'){
        dispatch({type: 'showDensityRow', payload:false});
        dispatch({type:'specificGravityError', payload:''})
        dispatch({type:'specificGravity', payload:'border-required'})
        dispatch({type: 'gasCustomCompressibility', payload:'border-disabled'});
        dispatch({ type: 'showCompressibility', payload: false });
        
      }else if(props.units_density_heading === 'Molecular Weight' || props.units_density_heading === 'Specific Gravity'){
        //show density row 
        dispatch({type: 'showDensityRow', payload:true});
        dispatch({ type: 'showCompressibility', payload: true });
        dispatch({type:'specificGravityError', payload:''})
        dispatch({type:'specificGravity', payload:'border-required'})
        dispatch({type:'molecularWeightError', payload:''})
        dispatch({type:'molecularWeight', payload:'border-required'})
        dispatch({type: 'gasCustomCompressibility', payload:'border-required'});
      
      }
    }else if (props.fluid_source.toLowerCase() === 'database'){
      dispatch({type:'velocitySonic',payload: 'border-disabled'})
      dispatch({ type: 'densityMin', payload: 'border-disabled' });
      dispatch({ type: 'densityNormal', payload: 'border-disabled' });
      dispatch({ type: 'densityMax', payload: 'border-disabled' });
    }else if (props.fluid_source ===CustomTranslationText('XXSPECIFY_MMI_SR_LBL_NATGAS',props)) {
      dispatch({type:'velocitySonic',payload: 'border-disabled'})
      dispatch({ type: 'densityMin', payload: 'border-disabled' });
      dispatch({ type: 'densityNormal', payload: 'border-disabled' });
      dispatch({ type: 'densityMax', payload: 'border-disabled' });
    }
  } else {
   
    dispatch({ type: 'densityHeadingDisabled', payload: false });
    dispatch({ type: 'showNonNewtonianCheckbox', payload: true });
    dispatch({ type: 'densityMin', payload: 'border-not-required' });
    dispatch({ type: 'densityNormal', payload: 'border-required' });
    dispatch({ type: 'densityMax', payload: 'border-required' });
    dispatch({ type: 'viscosityMax', payload: 'border-required' });
    dispatch({ type: 'vaporPressureMin', payload: 'border-not-required' });
    dispatch({type: 'showDensityRow', payload:false});
    dispatch({ type: 'showSonicVelocityRow', payload: false });
    dispatch({type:'specificGravity', payload:'border-required'})
    if(props.fluid_state.toLowerCase()==='slurry'){
      dispatch({ type: 'showCompressibility', payload: false });
    }
    if (props.non_newtonian_liquid) {
      dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
      dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
      dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
      dispatch({
        type: 'kFlowConsistencyIndex',
        payload: 'border-not-required',
      });
      dispatch({ type: 'nFlowBehavior', payload: 'border-not-required' });
    } else {
      dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
      if (state.normalViscosityError !== '') {
        dispatch({
          type: 'normalViscosityError',
          payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG0', props)],
        });
        dispatch({ type: 'viscosityNormal', payload: 'border-error' });
      } else {
        dispatch({ type: 'viscosityNormal', payload: 'border-required' });
      }
    }
  }

}
  useEffect(() => {
    if (props.select_technology.toLowerCase() === 'coriolis') {
     Coriolis()
    } else if (props.select_technology.toLowerCase() === 'vortex') {
      Vortex()
    } else if (props.select_technology.toLowerCase() === 'magnetic') {
     Magnetic()
    } else if (
      props.select_technology.toLowerCase() === 'viscosity' ||
      props.select_technology.toLowerCase() === 'density'
    ) {
     Density()
    }
  }, [props.select_technology,
    props.fluid_state,
    props.fluid_source,
    props.saved_preferences,
    props.calculate_density_variation,
    props.units_density_heading,
  props.base_reference_conditions,
  props.tank]);

  const optionsUnitsDensityHeading = jsonInputs.data
    .find((x) => x['units_density_heading'])
    ['units_density_heading'].map((x) => x.value);
  const optionsMinimumAccuracyDrop = jsonInputs.data
    .find((x) => x['units_minimum_accuracy'])
    ['units_minimum_accuracy'].map((x) => x.value);
  const optionsUnitsSonicVelocity = jsonInputs.data
    .find((x) => x['units_sonic_velocity'])
    ['units_sonic_velocity'].map((x) => x.value);
  const optionsUnitsGasPressure = jsonInputs.data
    .find((x) => x['units_gas_pressure'])
    ['units_gas_pressure'].map((x) => x.value);
  const optionsUnitsGasTemperature = jsonInputs.data
    .find((x) => x['units_temperature'])
    ['units_temperature'].map((x) => x.value);
  const optionsUnitsGasDensity = jsonInputs.data
    .find((x) => x['units_density'])
    ['units_density'].map((x) => x.value);
  // TODO: current bug that when key should be 0, it is undefined, changeKey is being used for that
  const handleUnitsDensityChange = (key) => {
    const changeKey = key ? parseInt(key) : 0;
    if (
      state.prevDensityDropdownKey < 0 &&
      props.saved_preferences.density_unit
    ) {
      dispatch({
        type: 'prevDensityDropdownKey',
        payload: props.units_data.density.findIndex(
          (p) => p.key === props.units_density.key
        ),
      });
    } else if (state.prevDensityDropdownKey < 0) {
      dispatch({
        type: 'prevDensityDropdownKey',
        payload: props.units_data.density.findIndex(
          (p) => p.key === props.units_density.key
        ),
      });
    } else {
      dispatch({
        type: 'prevDensityDropdownKey',
        payload: state.densityDropdownKey,
      });
    }
    dispatch({ type: 'densityDropdownKey', payload: changeKey });
      dispatch({ type: 'uomDensityKey', payload: changeKey });
    if(props.normal_density===''){
      dispatch({ type: 'oppCondition', payload: '' }); 
    }else{
      dispatch({ type: 'oppCondition', payload: DENSITY_VALUES });
    }
   
  };

  const handleUnitsViscosityChange = (key) => {
    const changeKey = key ? parseInt(key) : 0;
    if (
      state.prevViscosityDropdownKey < 0 &&
      props.saved_preferences.viscosity_unit
    ) {
      dispatch({
        type: 'prevViscosityDropdownKey',
        payload: props.units_data.viscosity.findIndex(
          (p) => p.key === props.units_viscosity.key
        ),
      });
    } else if (state.prevViscosityDropdownKey < 0) {
      dispatch({
        type: 'prevViscosityDropdownKey',
        payload: props.units_data.viscosity.findIndex(
          (p) => p.key === props.units_viscosity.key
        ),
      });
    } else {
      dispatch({
        type: 'prevViscosityDropdownKey',
        payload: state.viscosityDropdownKey,
      });
    }
    dispatch({ type: 'viscosityDropdownKey', payload: changeKey });
    dispatch({ type: 'uomViscosityKey', payload: changeKey });
    if(props.normal_viscosity===''){
      dispatch({ type: 'oppCondition', payload: '' });
    }else{
      dispatch({ type: 'oppCondition', payload: VISCOSITY_VALUES });
    }
  };

  const handleUnitsVaporPressureChange = (key) => {
    const changeKey = key ? parseInt(key) : 0;
    if (
      state.prevVaporPressureDropdownKey < 0 &&
      props.saved_preferences.vaporpressure_unit
    ) {
      dispatch({
        type: 'prevVaporPressureDropdownKey',
        payload: props.units_data.vaporPressure.findIndex(
          (p) => p.key === props.units_vapor.key
        ),
      });
    } else if (state.prevVaporPressureDropdownKey < 0) {
      dispatch({
        type: 'prevVaporPressureDropdownKey',
        payload: props.units_data.vaporPressure.findIndex(
          (p) => p.key === props.units_vapor.key
        ),
      });
    } else {
      dispatch({
        type: 'prevVaporPressureDropdownKey',
        payload: state.vaporPressureDropdownKey,
      });
    }
    dispatch({ type: 'vaporPressureDropdownKey', payload: changeKey });
    dispatch({ type: 'uomVaporPressureKey', payload: changeKey });
    dispatch({ type: 'oppCondition', payload: VAPOR_PRESSURE_VALUES });
  };

  const handleUnitsMaxPressureDropChange = (key) =>
    props.unitsMaxPressureDrop(state.maxPressureDropDropdown[key ? key : 0]);

  const handleUnitsDensityHeadingChange = (key) => {
    let selected = optionsUnitsDensityHeading[key ? key : 0]
    props.unitsDensityHeading(selected);
    if (selected !== "Specific Gravity") {
      props.specificGravity('');
    }
    if (selected !== "Density") {
      if (props.saved_preferences.density_unit) {
        dispatch({
          type: 'uomDensityKey', payload: props.units_data.density.findIndex(
            (p) => p.key === props.saved_preferences.density_unit
          )
        });
      }
    }
  };

  const handleUnitsPressureAtmosphericChange = (key) => {
    const changeKey = key ? parseInt(key) : 0;
    props.unitsPressureAtmospheric(state.vaporPressureDropdownUnits[changeKey ? changeKey : 0])
  };

  const handleUnitsMinimumAccuracyChange = (key) =>
    props.unitsMinimumAccuracy(optionsMinimumAccuracyDrop[key ? key : 0]);

  const handleUnitsSonicVelocityChange = (key) => {
    const changeKey = key ? parseInt(key) : 0;
    
    if (
      state.prevSonicVelocityDropdownKey < 0 &&
      props.saved_preferences.sonic_velocity_unit
    ) {
      dispatch({
        type: 'prevSonicVelocityDropdownKey',
        payload: props.units_data.sonicVelocity.findIndex(
          (p) => p.key === props.saved_preferences.sonic_velocity_unit
        ),
      });
    } else if (state.prevSonicVelocityDropdownKey < 0) {
      dispatch({
        type: 'prevSonicVelocityDropdownKey',
        payload: props.units_data.sonicVelocity.findIndex(
          (p) => p.key ===  props.units_sonic_velocity.key
        ),
      });
    } else {
      dispatch({
        type: 'prevSonicVelocityDropdownKey',
        payload: state.SonicVelocityDropdownKey,
      });
    }
    dispatch({ type: 'SonicVelocityDropdownKey', payload: changeKey });
    dispatch({ type: 'uomSonicVelocityKey', payload: changeKey });
    dispatch({ type: 'oppCondition', payload: SONIC_VELOCITY_VALUES });
    
  };

  const handleUnitsGasPressureChange = (key) => {

      const changeKey = key ? parseInt(key) : 0;
      if (
        state.prevPressureDropdownKey < 0 &&
        props.saved_preferences.gasonly_pressure_unit
      ) {
        dispatch({
          type: 'prevPressureDropdownKey',
          payload: props.units_data.vaporPressure.findIndex(
            (p) => p.key === props.saved_preferences.gasonly_pressure_unit
          ),
        });
      } else if (state.prevPressureDropdownKey  < 0) {
       
        dispatch({
          type: 'prevPressureDropdownKey',
          payload: props.units_data.vaporPressure.findIndex(
            (p) => p.key === GAS_ONLY_PRESSURE_UNIT
          ),
        });
      } else {
        dispatch({
          type: 'prevPressureDropdownKey',
          payload: state.pressureDropdownKey,
        });
      }
      dispatch({ type: 'pressureDropdownKey', payload: changeKey });
      dispatch({ type: 'uomPressureKey', payload: changeKey });
      dispatch({ type: 'oppCondition', payload: GAS_PRESSURE_VALUES });
    
    
  

  };

  const outputstring = <div>Pa.S<sup>n</sup></div>

  const handleUnitsGasTemperatureChange = (key) => {
      const changeKey = key ? parseInt(key) : 0;
      if (
        state.prevTemperatureDropdownKey < 0 &&
        props.saved_preferences.gasonly_temperature_unit
      ) {
        dispatch({
          type: 'prevTemperatureDropdownKey',
          payload: props.units_data.temperature.findIndex(
            (p) => p.key === props.saved_preferences.gasonly_temperature_unit
          ),
        });
      } else if (state.prevTemperatureDropdownKey  < 0) {
       
        dispatch({
          type: 'prevTemperatureDropdownKey',
          payload: props.units_data.temperature.findIndex(
            (p) => p.key === GAS_ONLY_TEMPERATURE_UNIT
          ),
        });
      } else {
        dispatch({
          type: 'prevTemperatureDropdownKey',
          payload: state.temperatureDropdownKey,
        });
      }
      dispatch({ type: 'temperatureDropdownKey', payload: changeKey });
      dispatch({ type: 'uomTemperatureKey', payload: changeKey });
      dispatch({ type: 'oppCondition', payload: GAS_TEMP_VALUES });
    
    
  };
  useEffect(() => {
    if (state.pressureDropdownKey !== state.prevPressureDropdownKey) {
      let gasPreValue =props?.units_data.vaporPressure?.[state.uomPressureKey]?.key?.toUpperCase();
      props.unitsGasPressure(getUnitsKeyValue(
        gasPreValue,
        VAPOR_PRESSURE_VALUES,
        props.units_data
      ),)
      dispatch({ type: 'pressureDropdownKey', payload: state.uomPressureKey });
    }
  }, [
    state.uomPressureKey,
    state.pressureDropdownKey,
    state.prevPressureDropdownKey,
  ]);
  useEffect(() => {
 
    if (state.temperatureDropdownKey !== state.prevTemperatureDropdownKey) {
      let gasTempValue = optionsUnitsGasTemperature?.[state.uomTemperatureKey]?.toUpperCase();
      props.unitsGasTemperature( getUnitsKeyValue(
          gasTempValue,
          TEMPERATURE_VALUES,
          props.units_data
        ),
      );
      dispatch({ type: 'temperatureDropdownKey', payload: state.uomTemperatureKey });
    }
  }, [
    state.uomTemperatureKey,
    state.temperatureDropdownKey,
    state.prevTemperatureDropdownKey,
  ]);
  const handleUnitsGasDensityChange = (key) => {
    const changeKey = key ? parseInt(key) : 0;
    if (
      state.prevGasDensityDropdownKey < 0 &&
      props.saved_preferences.gasonly_density_unit
    ) {
      dispatch({
        type: 'prevGasDensityDropdownKey',
        payload: props.units_data.density.findIndex(
          (p) => p.key === props.saved_preferences.gasonly_density_unit
        ),
      });
    } else if (state.prevGasDensityDropdownKey < 0) {
      dispatch({
        type: 'prevGasDensityDropdownKey',
        payload: props.units_data.density.findIndex(
          (p) => p.key === GAS_ONLY_DENSITY_UNIT
        ),
      });
    } else {
      dispatch({
        type: 'prevGasDensityDropdownKey',
        payload: state.gasDensityDropdownKey,
      });
    }
    dispatch({ type: 'gasDensityDropdownKey', payload: changeKey });
    dispatch({ type: 'uomGasDensityKey', payload: changeKey });
    dispatch({ type: 'oppCondition', payload: GAS_DENSITY_VALUES }); //make unit type
  };

  const handleUnitsGasDensitySGChange = (key)=>{
    const changeKey = key ? parseInt(key) : 0;
    if (
      state.prevGasDensitySGDropdownKey < 0 &&
      props.saved_preferences.gasonly_density_unit
    ) {
      dispatch({
        type: 'prevGasDensitySGDropdownKey',
        payload: props.units_data.density.findIndex(
          (p) => p.key === props.saved_preferences.gasonly_density_unit
        ),
      });
    } else if (state.prevGasDensitySGDropdownKey < 0) {
      dispatch({
        type: 'prevGasDensitySGDropdownKey',
        payload: props.units_data.density.findIndex(
          (p) => p.key === GAS_ONLY_DENSITY_UNIT
        ),
      });
    } else {
      dispatch({
        type: 'prevGasDensitySGDropdownKey',
        payload: state.gasDensitySGDropdownKey,
      });
    }
    dispatch({ type: 'gasDensitySGDropdownKey', payload: changeKey });
    dispatch({ type: 'uomGasDensitySGKey', payload: changeKey });
    dispatch({ type: 'oppCondition', payload: GAS_DENSITY_SG_VALUES }); 
  }

  // if previous and current dropdown selections are not the same
  useEffect(() => {
    if (state.densityDropdownKey !== state.prevDensityDropdownKey) {
      props.unitsDensity(props.units_data.density[state.uomDensityKey])
      dispatch({ type: 'densityDropdownKey', payload: state.uomDensityKey });
    }
  }, [
    state.uomDensityKey,
    state.densityDropdownKey,
    state.prevDensityDropdownKey,
    state.densityDropdownUnits,
  ]);

//if previous and current dropdown selections are not the same
useEffect(() => {
  if (state.gasDensityDropdownKey !== state.prevGasDensityDropdownKey) {
    props.unitsGasDensity(state.gasDensityDropdownUnits[state.uomGasDensityKey])
    dispatch({ type: 'gasDensityDropdownKey', payload: state.uomGasDensityKey });
  }
}, [
  state.uomGasDensityKey,
  state.gasDensityDropdownKey,
  state.prevGasDensityDropdownKey,
  state.gasDensityDropdownUnits,
]);

//if previous and current dropdown selections are not the same
useEffect(() => {
  if (state.gasDensitySGDropdownKey !== state.prevGasDensitySGDropdownKey) {
    props.unitsGasDensitySG(state.gasDensitySGDropdownUnits[state.uomGasDensitySGKey])
    dispatch({ type: 'gasDensitySGDropdownKey', payload: state.uomGasDensitySGKey });
  }
}, [
  state.uomGasDensitySGKey,
  state.gasDensitySGDropdownKey,
  state.prevGasDensitySGDropdownKey,
  state.gasDensitySGDropdownUnits,
]);


  // if previous and current dropdown selections are not the same
  useEffect(() => {
    if (state.SonicVelocityDropdownKey !== state.prevSonicVelocityDropdownKey) {
      let sonicValue =  props.units_data.sonicVelocity[state.uomSonicVelocityKey];
       props.unitsSonicVelocity( getUnitsKeyValue(
        sonicValue,
        SONIC_VELOCITY_VALUES,
        props.units_data
      ),
    );
      dispatch({ type: 'SonicVelocityDropdownKey', payload: state.uomSonicVelocityKey });
    }
  }, [
    state.uomSonicVelocityKey,
    state.SonicVelocityDropdownKey,
    state.prevDensityDropdownKey,
    state.densityDropdownUnits,
  ]);
  useEffect(() => {
    if (state.viscosityDropdownKey !== state.prevViscosityDropdownKey) {
      props.unitsViscosity(state.viscosityDropdownUnits[state.uomViscosityKey]) 
      dispatch({
        type: 'viscosityDropdownKey',
        payload: state.uomViscosityKey,
      });
    }
  }, [
    state.uomViscosityKey,
    state.viscosityDropdownKey,
    state.prevViscosityDropdownKey,
    state.viscosityDropdownUnits,
  ]);

  useEffect(() => {
    if (state.vaporPressureDropdownKey !== state.prevVaporPressureDropdownKey) {
      props.unitsVapor(state.vaporPressureDropdownUnits[state.uomVaporPressureKey]) 
      dispatch({
        type: 'vaporPressureDropdownKey',
        payload: state.uomVaporPressureKey,
      });
    }
  }, [
    state.uomVaporPressureKey,
    state.vaporPressureDropdownKey,
    state.prevVaporPressureDropdownKey,
    state.vaporPressureDropdownUnits,
  ]);

  useEffect(() => {
    if (props.units_data) {
      props.saved_preferences.density_unit
      ?props.unitsDensity(getUnitsKeyValue(
        props.units_density,
        DENSITY_VALUES,
        props.units_data
      )) 
      :props.unitsDensity(getUnitsKeyValue(
        props.units_density,
        DENSITY_VALUES,
        props.units_data
      ))

      props.saved_preferences.viscosity_unit
      ?props.unitsViscosity(getUnitsKeyValue(
        props.units_viscosity,
        VISCOSITY_VALUES,
        props.units_data
      )) 
      :props.unitsViscosity(getUnitsKeyValue(
        props.units_viscosity,
        VISCOSITY_VALUES,
        props.units_data
      )) 
      props.saved_preferences.vaporpressure_unit
      ? props.unitsVapor(getUnitsKeyValue(
        props.units_vapor,
        VAPOR_PRESSURE_VALUES,
        props.units_data
      )) 
      : props.unitsVapor(getUnitsKeyValue(
        props.units_vapor,
        VAPOR_PRESSURE_VALUES,
        props.units_data
      ))

      props.saved_preferences.auto_con_uom
        ? dispatch({
            type: 'uomAutoConversion',
            payload: props.saved_preferences.auto_con_uom,
          })
        : dispatch({ type: 'uomAutoConversion', payload:props.uom_auto_conversion_pref });
    }
  }, [props.units_data, props.saved_preferences]);

  const {
    min_density,
    normal_density,
    gas_density,
    density_specific_gravity,
    max_density,
    min_viscosity,
    normal_viscosity,
    max_viscosity,
    min_vapor,
  } = props;
  useEffect(() => {
    if(props.custom_errors.length>0){
      if(props.custom_errors[0].viscosity_norm_error)
     {dispatch({
        type: 'normalViscosityError',
        payload: [
          props.custom_errors[0].viscosity_norm_error,
          CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props),
          CustomTranslationText('XXSPECIFY_MMI_MC_TXT_INPUT', props),
        ],
      })
      dispatch({ type: 'viscosityNormal', payload: 'border-error' })
    }else{
        dispatch({type: 'normalViscosityError',payload:'',})
      }
     if(props.custom_errors[0].viscosity_min_error)
     {dispatch({
        type: 'minViscosityError',
        payload: [
          props.custom_errors[0].viscosity_min_error,
          CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props),
          CustomTranslationText('XXSPECIFY_MMI_MC_TXT_INPUT', props),
        ], 
      })
      dispatch({ type: 'viscosityMin', payload: 'border-error' })
    }else{
      dispatch({type: 'minViscosityError',payload:'',})
    }
      if(props.custom_errors[0].viscosity_max_error)
      {dispatch({
         type: 'maxViscosityError',
         payload: [
           props.custom_errors[0].viscosity_max_error,
           CustomTranslationText('XXSPECIFY_MMI_MC_LBL_HINTMSG0', props),
            CustomTranslationText('XXSPECIFY_MMI_MC_TXT_INPUT', props)
         ],
       })
       dispatch({ type: 'viscosityMax', payload: 'border-error' })}
      else{
       
        
        dispatch({type: 'maxViscosityError',payload:'',})
     }
    }
    else{
      dispatch({type: 'minViscosityError',payload:'',})
      dispatch({type: 'normalViscosityError',payload:'',})
      dispatch({type: 'maxViscosityError',payload:'',})
   }
  }, [
   props.custom_errors
  ]);
  const array = useRef([]);
  const units = useRef([]);

// useEffect(() => {
//   if(props.fluid_source.toLowerCase()==='custom'){
  
//     if(state.oppCondition === VISCOSITY_VALUES &&!props.clear_all_field ){
//       setTimeout(() => {
//         dispatch({ type: 'oppCondition', payload: VISCOSITY_CP_VALUES });

//         setTimeout(() => {
//           dispatch({ type: 'oppCondition', payload: '' });
//         }, 500);
//       }, 500);
//     }

//     if(viscosityConvertionFlag&&!props.clear_all_field){
//       setTimeout(() => {
//         dispatch({ type: 'oppCondition', payload: VISCOSITY_CP_VALUES });
//         setViscosityConvertionFlag(false)
//         setTimeout(() => {
//           dispatch({ type: 'oppCondition', payload: '' });
//         }, 100);
//       }, 100);
//     }
//   }
// }, [state.oppCondition, viscosityConvertionFlag])


  useEffect(() => {
    if (state.oppCondition === DENSITY_VALUES) {
      array.current = [min_density, normal_density, max_density, ''];
      units.current = state.densityDropdownUnits;
    } else if(state.oppCondition === GAS_DENSITY_VALUES){
      array.current = [gas_density, '', '', ''];
      units.current = state.gasDensityDropdownUnits;
    }else if(state.oppCondition === GAS_DENSITY_SG_VALUES){
      array.current = [density_specific_gravity, '', '', ''];
      units.current = state.gasDensitySGDropdownUnits;
    } else if (state.oppCondition === VISCOSITY_VALUES) {
      array.current = [min_viscosity, normal_viscosity, max_viscosity, ''];
      units.current = state.viscosityDropdownUnits;
    } else if (state.oppCondition === VAPOR_PRESSURE_VALUES) {
      array.current = [min_vapor, '', '', ''];
      units.current = state.vaporPressureDropdownUnits;
    } else if (state.oppCondition === SONIC_VELOCITY_VALUES){
      array.current = [props.sonic_velocity, '', '', ''];
      units.current = props.units_data.sonicVelocity;
    }else if(state.oppCondition === VISCOSITY_CP_VALUES){
      array.current = [min_viscosity, normal_viscosity, max_viscosity, ''];
      units.current = state.viscosityDropdownUnits;
    }else if(state.oppCondition === GAS_TEMP_VALUES){
      array.current = ['', props.gas_temperature, '', ''];
      units.current = optionsUnitsGasTemperature;
    }else if(state.oppCondition === GAS_PRESSURE_VALUES){
      array.current = ['', props.gas_pressure, '', ''];
      units.current = props.units_data.vaporPressure;
    }
  }, [
    state.oppCondition,
    min_density,
    normal_density,
    max_density,
    state.densityDropdownUnits,
    min_viscosity,
    normal_viscosity,
    max_viscosity,
    props.sonic_velocity,
    state.viscosityDropdownUnits,
    min_vapor,
    state.vaporPressureDropdownUnits,
    gas_density, 
    density_specific_gravity,
    state.gasDensityDropdownUnits,
  ]);

  // These are used in useConvertForUom() custom hook
  //    unitType: the property or condition being converted
  //    value: number to be converted
  //    units: the object containing the dropdown keys
  //    unitKey: the converting to uom
  //    prevUnitKey: the converting from uom
  useEffect(() => {
    if (state.oppCondition === DENSITY_VALUES) {
      const uomArray = array.current.map((value) => {
        return {
          unitType: DENSITY_VALUES,
          value: value,
          units: units.current,
          unitKey: state.densityDropdownKey,
          prevUnitKey: state.prevDensityDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    }else if(state.oppCondition === GAS_DENSITY_VALUES){
      const uomArray = array.current.map((value) => {
        return {
          unitType: GAS_DENSITY_VALUES,
          value: value,
          units: units.current,
          unitKey: state.gasDensityDropdownKey,
          prevUnitKey: state.prevGasDensityDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    }else if(state.oppCondition === GAS_DENSITY_SG_VALUES){
      const uomArray = array.current.map((value) => {
        return {
          unitType: GAS_DENSITY_SG_VALUES,
          value: value,
          units: units.current,
          unitKey: state.gasDensitySGDropdownKey,
          prevUnitKey: state.prevGasDensitySGDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    } else if (state.oppCondition === VISCOSITY_VALUES) {
      const uomArray = array.current.map((value) => {
        return {
          unitType: VISCOSITY_VALUES,
          value: value,
          units: units.current,
          unitKey: state.viscosityDropdownKey,
          prevUnitKey: state.prevViscosityDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    } else if(state.oppCondition === VISCOSITY_CP_VALUES){
      const uomArray =array.current.map((value) => {
        return {
          unitType: VISCOSITY_CP_VALUES,
          value: value,
          units: units.current,
          unitKey: 2,
          prevUnitKey: state.viscosityDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    }
    else if (state.oppCondition === VAPOR_PRESSURE_VALUES) {
      const uomArray = array.current.map((value) => {
        return {
          unitType: VAPOR_PRESSURE_VALUES,
          value: value,
          units: units.current,
          unitKey: state.vaporPressureDropdownKey,
          prevUnitKey: state.prevVaporPressureDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    }else if (state.oppCondition === SONIC_VELOCITY_VALUES) {
      const uomArray = array.current.map((value) => {
        return {
          unitType: SONIC_VELOCITY_VALUES,
          value: value,
          units: units.current,
          unitKey: state.SonicVelocityDropdownKey,
          prevUnitKey: state.prevSonicVelocityDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    }
    else if (state.oppCondition === GAS_TEMP_VALUES) {
      const uomArray = array.current.map((value) => {
        return {
          unitType: GAS_TEMP_VALUES,
          value: value,
          units: units.current,
          unitKey: state.temperatureDropdownKey,
          prevUnitKey: state.prevTemperatureDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    }else if (state.oppCondition === GAS_PRESSURE_VALUES) {
      const uomArray = array.current.map((value) => {
        return {
          unitType: GAS_PRESSURE_VALUES,
          value: value,
          units: units.current,
          unitKey: state.pressureDropdownKey,
          prevUnitKey: state.prevPressureDropdownKey,
        };
      });
      dispatch({ type: 'uomObjectArray', payload: uomArray });
    }
    state.uomAutoConversion === 'ENABLED' && state.oppCondition !== GAS_DENSITY_VALUES
      ? dispatch({ type: 'uomFlag', payload: true })
      : dispatch({ type: 'uomFlag', payload: true });

    if(state.oppCondition === GAS_DENSITY_VALUES){
      props.setCalcCall(true);
    }
  }, [
    state.oppCondition,
    state.uomAutoConversion,
    state.densityDropdownKey,
    state.gasDensityDropdownKey,
    state.gasDensitySGDropdownKey,
    state.SonicVelocityDropdownKey,
    state.prevSonicVelocityDropdownKey,
    state.prevDensityDropdownKey,
    state.prevGasDensityDropdownKey,
    state.prevGasDensitySGDropdownKey,
    state.viscosityDropdownKey,
    state.prevViscosityDropdownKey,
    state.vaporPressureDropdownKey,
    state.prevVaporPressureDropdownKey,
    state.temperatureDropdownKey,
    state.prevTemperatureDropdownKey,
    state.uomTemperatureKey,
    state.pressureDropdownKey,
    state.prevPressureDropdownKey,
    state.uomPressureKey
  ]);

  // Custom hook to do the API call
  let uomResults = useConvertForUom(state.uomObjectArray, state.uomFlag, props);

  // when we get results set flag to false to avoid repeat calls
  useEffect(() => {
    if (uomResults) {
      dispatch({ type: 'uomFlag', payload: false });
    }
  }, [uomResults]);

  const {
    minDensity,
    minDensityValue,
    normalDensity,
    normalDensityValue,
    maxDensity,
    maxDensityValue,
    minViscosity,
    minViscosityValue,
    normalViscosity,
    normalViscosityValue,
    maxViscosity,
    maxViscosityValue,
    minVapor,
    minVaporValue,
    sonicVelocityValue,
  } = props;

  const resultsFixDecimalPlaces = useCallback(() => {
    if (state.oppCondition === DENSITY_VALUES) {
      minDensity(
        state.densityMin === 'border-disabled'?
        uomResults[0].min? uomResults[0].min.toString():'':
        fixToDecimalPlaces(uomResults[0].min, state.decimalPlaces, reg)
      );

      props.minDensityValue(
        uomResults[0].min? uomResults[0].min.toString() :''
      );

      normalDensity(
        state.densityNormal === 'border-disabled'?
        uomResults[1].norm? uomResults[1].norm.toString():'':
        fixToDecimalPlaces(uomResults[1].norm, state.decimalPlaces, reg)
      );
        
      props.normalDensityValue(
        uomResults[1].norm? uomResults[1].norm.toString():''
      )

      maxDensity(
        state.densityMax ==='border-disabled'?
        uomResults[2].max? uomResults[2].max.toString():'':
        fixToDecimalPlaces(uomResults[2].max, state.decimalPlaces, reg)
      );

      props.maxDensityValue(
        uomResults[2].max?uomResults[2].max.toString():''
      );
    } else if(state.oppCondition === GAS_DENSITY_VALUES){

      props.gasDensity(
        state.gasDensity === 'border-disabled'?
        uomResults[0].min? uomResults[0].min.toString():'':
        fixToDecimalPlaces(uomResults[0].min, state.decimalPlaces, reg)
        )
      props.gasDensityValue(uomResults[0].min? uomResults[0].min.toString():'')
    }else if(state.oppCondition === GAS_DENSITY_SG_VALUES){
      props.densitySG(
        state.densitySG === 'border-disabled'?
        uomResults[0].min? uomResults[0].min.toString():'':
        fixToDecimalPlaces(uomResults[0].min, state.decimalPlaces, reg)
        )
      props.densitySGValue(uomResults[0].min? uomResults[0].min.toString():'')
    } else if (state.oppCondition === VISCOSITY_VALUES) {
      minViscosity(
        state.viscosityMin === 'border-disabled'?
        uomResults[0].min? uomResults[0].min.toString():'':
        fixToDecimalPlaces(uomResults[0].min, state.decimalPlaces, reg)
      );

      minViscosityValue(
        uomResults[0].min? uomResults[0].min.toString() :''
      );

      normalViscosity(
        props.fluid_source.toLowerCase()!=='natural gas'?
        state.viscosityNormal === 'border-disabled'?
        uomResults[1].norm? uomResults[1].norm.toString():'':
        fixToDecimalPlaces(uomResults[1].norm, state.decimalPlaces, reg):
        uomResults[1].norm? uomResults[1].norm.toString():''
      );

      normalViscosityValue(
        uomResults[1].norm? uomResults[1].norm.toString():''
      );

      maxViscosity(
        state.viscosityMax === 'border-disabled'?
        uomResults[2].max? uomResults[2].max.toString():'':
        fixToDecimalPlaces(uomResults[2].max, state.decimalPlaces, reg)
      );

      maxViscosityValue(
        uomResults[2].max?uomResults[2].max.toString():''
      );

    }else if (state.oppCondition === VAPOR_PRESSURE_VALUES) {
      minVapor(
        state.densityMax === 'border-disabled'?
        uomResults[0].min? uomResults[0].min.toString() :'':
        fixToDecimalPlaces(uomResults[0].min, state.decimalPlaces, reg)
        );
      minVaporValue(
        uomResults[0].min? uomResults[0].min.toString() :''
      )
    }else if (state.oppCondition === SONIC_VELOCITY_VALUES) {
       props.sonicVelocity(
        state.velocitySonic === 'border-disabled'?
      uomResults[0].min? uomResults[0].min.toString() :'':
        fixToDecimalPlaces(uomResults[0].min, state.decimalPlaces, reg));
      sonicVelocityValue(
        uomResults[0].min? uomResults[0].min.toString() :''
      )
    }
    else if (state.oppCondition === GAS_TEMP_VALUES) {
      props.gasTemperature(fixToDecimalPlaces(uomResults[1].norm, state.decimalPlaces, reg));
   }
   else if (state.oppCondition === GAS_PRESSURE_VALUES) {
    props.gasPressure(fixToDecimalPlaces(uomResults[1].norm, state.decimalPlaces, reg));
 }
  }, [
    state.oppCondition,
    uomResults,
    minDensity,
    minDensityValue,
    normalDensity,
    maxDensity,
    minViscosity,
    normalViscosity,
    maxViscosity,
    minVapor,
    state.viscosityDropdownKey,
  ]);

  //extract the ui changes and reset properties of no newtonian checkbox funtionality
  useEffect(() => {
    if(!props.non_newtonian_liquid_flag && resizingFlag ){

      setTimeout(() => {
        if(props.non_newtonian_liquid){

          dispatch({
            type: 'minViscosityError',
            payload: '',
          });
          dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
          props.minViscosityConvertionError(false);
          dispatch({
            type: 'normalViscosityError',
            payload: '',
          });
          dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
          props.normalViscosityConvertionError(false);
          dispatch({
            type: 'maxViscosityError',
            payload: '',
          });
          dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
          props.maxViscosityConvertionError(false);
        }else{
          if(props.fluid_source.toLowerCase()=== 'custom' && props.fluid_state.toLowerCase()==='liquid'||props.fluid_state.toLowerCase()==='slurry'){
            dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
            dispatch({ type: 'viscosityNormal', payload: 'border-required' });
            if(props.select_technology ==='Density' || props.select_technology ==='Viscosity'){
              dispatch({ type: 'viscosityMax', payload: 'border-required' });
            }else{
              dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
            }  
          }
          
        }
      }, 500);
      
    }
     
  }, [props.non_newtonian_liquid, props.non_newtonian_liquid_flag])
 
   
  useEffect(() => {
    if (uomResults) {
      resultsFixDecimalPlaces();
      //convertion units validations for viscosity fields
      var minViscosityConvertValue =''
      var maxViscosityConvertValue = ''
      var normalViscosityConvertValue = ''
      if(state.oppCondition === ''){
        minViscosityConvertValue =  uomResults[0].min !== "" && uomResults[0].min !== null ? Number.parseFloat(uomResults[0].min) : '';
        normalViscosityConvertValue = uomResults[1].norm !== "" && uomResults[1].norm !== null ? Number.parseFloat(uomResults[1].norm):'';
        maxViscosityConvertValue  = uomResults[2].max !== "" && uomResults[2].max !== null ? Number.parseFloat(uomResults[2].max):'';
        
        //allow the validation for all those units except kinematic ones
        if(props.units_viscosity !==undefined? props.units_viscosity.flow_type !== 'Kinematic':false && props.fluid_source.toLowerCase()=== 'custom'){
          //min viscosity convertion value
          if(minViscosityConvertValue !=='' && minViscosityConvertValue !== NaN){
            if(minViscosityConvertValue < 0.01){ 
                dispatch({
                  type: 'minViscosityError',
                  payload:[CustomTranslationText('XXSPECIFY_MMI_PRE_VISC_VALD', props)],
                });
                dispatch({ type: 'viscosityMin', payload: 'border-error' });
                props.minViscosityConvertionError(true)
            }else{
                if(props.fluid_source.toLowerCase()==='custom'){
                  dispatch({
                    type: 'minViscosityError',
                    payload: '',
                  });
                  dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
                  props.minViscosityConvertionError(false)
                }else if(props.fluid_source.toLowerCase()==='database'){
                  dispatch({
                    type: 'minViscosityError',
                    payload: '',
                  });
                  dispatch({ type: 'viscosityMin', payload: 'border-disabled' });
                }
            }
          }else{
            if(props.fluid_source.toLowerCase()==='custom'){
              dispatch({
                type: 'minViscosityError',
                payload: '',
              });
              dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
              props.minViscosityConvertionError(false)
            }
          }
          //normal viscosity convertion value
          if(normalViscosityConvertValue !=='' && normalViscosityConvertValue !== NaN){
            if(normalViscosityConvertValue < 0.01){
                dispatch({
                  type: 'normalViscosityError',
                  payload:[CustomTranslationText('XXSPECIFY_MMI_PRE_VISC_VALD', props)],
                });
                dispatch({ type: 'viscosityNormal', payload: 'border-error' });
                props.normalViscosityConvertionError(true)
                props.normalViscosityTypeErrors('')
            }
            
            else{ 
                if(props.fluid_source.toLowerCase()==='custom' && minViscosityConvertValue !== ''){
                  if(props.fluid_source.toLowerCase()==='custom' && normalViscosityConvertValue < minViscosityConvertValue){
                    dispatch({ type: 'viscosityNormal', payload: 'border-error' });
                    props.normalViscosityMinMaxErrors(true)
                    props.normalViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG110', props))
                    dispatch({
                      type: 'normalViscosityError',
                      payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG110', props)],
                    });
                  }else if(props.fluid_source.toLowerCase()==='custom' && normalViscosityConvertValue >= minViscosityConvertValue&&!props.non_newtonian_liquid){
                    dispatch({
                      type: 'normalViscosityError',
                      payload: '',
                    });
                    dispatch({ type: 'viscosityNormal', payload: 'border-required' });
                    props.normalViscosityConvertionError(false)
                    props.normalViscosityMinMaxErrors(false)
                    props.normalViscosityTypeErrors('')
                  }
                }else if(props.fluid_source.toLowerCase()==='custom' && minViscosityConvertValue === ''&&!props.non_newtonian_liquid){
                  dispatch({
                    type: 'normalViscosityError',
                    payload: '',
                  });
                  dispatch({ type: 'viscosityNormal', payload: 'border-required' });
                  props.normalViscosityConvertionError(false)
                  props.normalViscosityMinMaxErrors(false)
                  props.normalViscosityTypeErrors('')
                } else if(props.fluid_source.toLowerCase()==='database'){
                  dispatch({
                    type: 'normalViscosityError',
                    payload: '',
                  });
                  dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
                  props.normalViscosityTypeErrors('')
                }      
             
            }
            
          }
          //max viscosity convertion value
          if(maxViscosityConvertValue !=='' && maxViscosityConvertValue !== NaN){
            if(maxViscosityConvertValue < 0.01){
                dispatch({
                  type: 'maxViscosityError',
                  payload:[CustomTranslationText('XXSPECIFY_MMI_PRE_VISC_VALD', props)],
                });
                dispatch({ type: 'viscosityMax', payload: 'border-error' });
                props.maxViscosityConvertionError(true)
            }
            
            else{
                if(props.select_technology.toLowerCase()==='coriolis'||props.select_technology.toLowerCase()==='vortex'){
                  if(props.fluid_source.toLowerCase()==='custom' && minViscosityConvertValue !== '' && normalViscosityConvertValue === ''){
                    if(minViscosityConvertValue > maxViscosityConvertValue){
                      dispatch({ type: 'viscosityMax', payload: 'border-error' });
                      dispatch({
                        type: 'maxViscosityError',
                        payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props)],
                      });
                      props.maxViscosityMinMaxErrors(true)
                      props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props))
                    }else {
                      dispatch({
                        type: 'maxViscosityError',
                        payload: '',
                      });
                      dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
                      props.maxViscosityConvertionError(false)
                      props.maxViscosityMinMaxErrors(false)
                      props.maxViscosityTypeErrors('')
                    }
                  }else if(props.fluid_source.toLowerCase()==='custom' && normalViscosityConvertValue !== '' && minViscosityConvertValue === ''){
                    if(normalViscosityConvertValue > maxViscosityConvertValue){
                      dispatch({ type: 'viscosityMax', payload: 'border-error' });    
                      dispatch({
                        type: 'maxViscosityError',
                        payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props)],
                      });
                      props.maxViscosityMinMaxErrors(true)
                      props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props))
                    }else {
                      dispatch({
                        type: 'maxViscosityError',
                        payload: '',
                      });
                      dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
                      props.maxViscosityConvertionError(false)
                      props.maxViscosityMinMaxErrors(false)
                      props.maxViscosityTypeErrors('')
                    }
                  }else if(props.fluid_source.toLowerCase()==='custom' && minViscosityConvertValue !== '' && normalViscosityConvertValue !== ''){
                    if(minViscosityConvertValue > maxViscosityConvertValue || normalViscosityConvertValue > maxViscosityConvertValue){
                      dispatch({ type: 'viscosityMax', payload: 'border-error' });
                      dispatch({
                        type: 'maxViscosityError',
                        payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props)],
                      });
                      props.maxViscosityMinMaxErrors(true)
                      props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props))
                      if(minViscosityConvertValue > maxViscosityConvertValue){
                        dispatch({
                          type: 'maxViscosityError',
                          payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props)],
                        });
                        props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props))
                      }
                      
                    }else if(minViscosityConvertValue <= maxViscosityConvertValue && normalViscosityConvertValue <= maxViscosityConvertValue){
                      dispatch({
                        type: 'maxViscosityError',
                        payload: '',
                      });
                      dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
                      props.maxViscosityConvertionError(false)
                      props.maxViscosityMinMaxErrors(false)
                      props.maxViscosityTypeErrors('')
                    }
                  }else if(props.fluid_source.toLowerCase()==='custom' && minViscosityConvertValue === '' && normalViscosityConvertValue === ''){
                    dispatch({
                      type: 'maxViscosityError',
                      payload: '',
                    });
                    dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
                    props.maxViscosityConvertionError(false)
                    props.maxViscosityMinMaxErrors(false)
                    props.maxViscosityTypeErrors('')
                  }else if(props.fluid_source.toLowerCase()==='database'){
                    dispatch({
                      type: 'maxViscosityError',
                      payload: '',
                    });
                    dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
                    props.maxViscosityMinMaxErrors(false)
                    props.maxViscosityTypeErrors('')
                  }

                } else if(props.fluid_source.toLowerCase()==='custom' && (props.viscosity || props.density)){
                  if(props.fluid_source.toLowerCase()==='custom' && minViscosityConvertValue !== '' && normalViscosityConvertValue === ''){
                    if(minViscosityConvertValue > maxViscosityConvertValue){
                      dispatch({ type: 'viscosityMax', payload: 'border-error' });
                      props.maxViscosityMinMaxErrors(true)
                      props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props))
                      dispatch({
                        type: 'maxViscosityError',
                        payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props)],
                      });

                    }else {
                      dispatch({
                        type: 'maxViscosityError',
                        payload: '',
                      });
                      if(props.flow){
                        dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
                      }else{
                        dispatch({ type: 'viscosityMax', payload: 'border-required' });
                      }
                      
                      props.maxViscosityConvertionError(false)
                      props.maxViscosityMinMaxErrors(false)
                      props.maxViscosityTypeErrors('')
                    }
                  }else if(props.fluid_source.toLowerCase()==='custom' && normalViscosityConvertValue !== '' && minViscosityConvertValue === ''){
                    if(normalViscosityConvertValue > maxViscosityConvertValue){
                      dispatch({ type: 'viscosityMax', payload: 'border-error' });    
                      dispatch({
                        type: 'maxViscosityError',
                        payload: '',
                      });
                      props.maxViscosityMinMaxErrors(true)
                      props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props))
                      dispatch({
                        type: 'maxViscosityError',
                        payload: [CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props)],
                      });
                    }else {
                      dispatch({
                        type: 'maxViscosityError',
                        payload: '',
                      });
                      if(props.flow){
                        dispatch({ type: 'viscosityMax', payload: 'border-not-required' });  
                      }else{
                        dispatch({ type: 'viscosityMax', payload: 'border-required' });
                      }
                      props.maxViscosityConvertionError(false)
                      props.maxViscosityMinMaxErrors(false)
                      props.maxViscosityTypeErrors('')
                    }                
                  }else if(props.fluid_source.toLowerCase()==='custom' && minViscosityConvertValue !== '' && normalViscosityConvertValue !== ''){
                    if(minViscosityConvertValue > maxViscosityConvertValue || normalViscosityConvertValue > maxViscosityConvertValue){
                      dispatch({ type: 'viscosityMax', payload: 'border-error' });
                      dispatch({
                        type: 'maxViscosityError',
                        payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props)],
                      });
                      props.maxViscosityMinMaxErrors(true)
                      props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG109', props))
                      if(minViscosityConvertValue > maxViscosityConvertValue){
                        dispatch({
                          type: 'maxViscosityError',
                          payload:[CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props)],
                        });
                        props.maxViscosityTypeErrors(CustomTranslationText('XXSPECIFY_MMI_MC_LBL_ERRMSG111', props))
                      }
                    }else if(minViscosityConvertValue <= maxViscosityConvertValue && normalViscosityConvertValue <= maxViscosityConvertValue){
                      dispatch({
                        type: 'maxViscosityError',
                        payload: '',
                      });
                      dispatch({ type: 'viscosityMax', payload: 'border-required' });
                      props.maxViscosityConvertionError(false)
                      props.maxViscosityMinMaxErrors(false)
                      props.maxViscosityTypeErrors('')
                    }
                  }else if(props.fluid_source.toLowerCase()==='custom' && minViscosityConvertValue === '' && normalViscosityConvertValue === ''){
                    dispatch({
                      type: 'maxViscosityError',
                      payload: '',
                    });
                    dispatch({ type: 'viscosityMax', payload: 'border-required' });
                    props.maxViscosityConvertionError(false)
                    props.maxViscosityMinMaxErrors(false)
                    props.maxViscosityTypeErrors('')
                  }else if(props.fluid_source.toLowerCase()==='database'){
                    dispatch({
                      type: 'maxViscosityError',
                      payload: '',
                    });
                    dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
                    props.maxViscosityMinMaxErrors(false)
                    props.maxViscosityTypeErrors('')
                  }
                }
            }
            


          }else{
              if(props.select_technology.toLowerCase()==='coriolis' || props.select_technology.toLowerCase()==='vortex' ){
                dispatch({
                  type: 'maxViscosityError',
                  payload: '',
                });
                dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
                props.maxViscosityConvertionError(false)
                props.maxViscosityMinMaxErrors(false)
                props.maxViscosityTypeErrors('')
              }else if(props.select_technology.toLowerCase()==='density' || props.select_technology.toLowerCase()==='viscosity'){
                
                
                props.maxViscosityConvertionError(false)
                props.maxViscosityMinMaxErrors(false)
                props.maxViscosityTypeErrors('')
              }
          }
        }else {
            if(props.fluid_source.toLowerCase()==='custom'&&!props.non_newtonian_liquid){
              dispatch({
                type: 'minViscosityError',
                payload: '',
              });
              dispatch({ type: 'viscosityMin', payload: 'border-not-required' });
              props.minViscosityConvertionError(false)

              dispatch({
                type: 'normalViscosityError',
                payload: '',
              });
              dispatch({ type: 'viscosityNormal', payload: 'border-required' });
              props.normalViscosityConvertionError(false)
              props.normalViscosityMinMaxErrors(false)
              props.normalViscosityTypeErrors('')

              if(props.viscosity || props.density){
                dispatch({
                  type: 'maxViscosityError',
                  payload: '',
                });
                dispatch({ type: 'viscosityMax', payload: 'border-required' });
                props.maxViscosityConvertionError(false)
                props.maxViscosityMinMaxErrors(false)
                props.maxViscosityTypeErrors('')
              }else{
                dispatch({
                  type: 'maxViscosityError',
                  payload: '',
                });
                dispatch({ type: 'viscosityMax', payload: 'border-not-required' });
                props.maxViscosityConvertionError(false)
                props.maxViscosityMinMaxErrors(false)
                props.maxViscosityTypeErrors('')
              }
            }
            else if(props.fluid_source.toLowerCase()==='database'){

              dispatch({
                type: 'minViscosityError',
                payload: '',
              });
              dispatch({ type: 'viscosityMin', payload: 'border-disabled' });

              dispatch({
                type: 'normalViscosityError',
                payload: '',
              });
              dispatch({ type: 'viscosityNormal', payload: 'border-disabled' });
              props.normalViscosityConvertionError(false)


              dispatch({
                type: 'maxViscosityError',
                payload: '',
              });
              dispatch({ type: 'viscosityMax', payload: 'border-disabled' });
              props.maxViscosityConvertionError(false)
              props.normalViscosityMinMaxErrors(false)
              props.maxViscosityMinMaxErrors(false)
              props.normalViscosityTypeErrors('')
              props.maxViscosityTypeErrors('')
            }
            
            props.normalViscosityMinMaxErrors(false)
            props.maxViscosityMinMaxErrors(false)
            props.normalViscosityTypeErrors('')
            props.maxViscosityTypeErrors('')
        }
      }
      
    }
  }, [uomResults, resultsFixDecimalPlaces]);

  //enable the sizing results button after validation for viscosity is done 
useEffect(() => {
  setTimeout(() => {
    props.convertDisableButton(false)  
  },1000);
  
}, [props.convert_disable_button])



  const insertValues = (event) => {
    switch (event.target.id) {
      case 'min-density':
        props.minDensity(event.target.value);
        if (event.target.value === '') {
          props.userEntered('CLEARID' + event.target.id)
        }
        if (props.fluid_state.toLowerCase() === 'gas' && props.max_temperature !== '' && props.min_line_pressure !== '' && event.target.value !== '') {
          props.userEntered('minDensity')
        }
        props.minDensityValue('');
        break;
      case 'normal-density':
        props.normalDensity(event.target.value);
        if (event.target.value === '') {
          props.userEntered('CLEARID' + event.target.id)
        }
        if (props.fluid_state.toLowerCase() === 'gas' &&  props.normal_temperature !== '' && props.normal_line_pressure !== '' && event.target.value !== '') {
          props.userEntered('normalDensity')
        }
        props.normalDensityValue('');
        break;
      case 'max-density':
        props.maxDensity(event.target.value);
        if (event.target.value === '') {
          props.userEntered('CLEARID' + event.target.id)
        }
        if (props.fluid_state.toLowerCase() === 'gas' && props.min_temperature !== '' && props.max_line_pressure !== '' && event.target.value !== '') {
          props.userEntered('maxDensity')
        }
        props.maxDensityValue('')
        break;
      case 'specific-gravity':
        props.specificGravity(event.target.value);
        break;
      case 'molecular-weight':
        props.molecularWeight(event.target.value);
        if (event.target.value === '') {
          props.userEntered('CLEARID' + event.target.id)
        }
        if (props.fluid_state.toLowerCase() === 'gas' &&  props.molecular_weight !== '' && event.target.value !== '') {
          props.userEntered('molecularWeight')
        }
        
        break;
      case 'min-viscosity':
        props.minViscosity(event.target.value);
        props.minViscosityConvertionError(false)
        props.minViscosityValue('')
        break;
      case 'normal-viscosity':
        props.normalViscosity(event.target.value);
        props.normalViscosityConvertionError(false)
        props.normalViscosityValue('')
        break;
      case 'max-viscosity':
        props.maxViscosity(event.target.value);
        props.minViscosityConvertionError(false)
        props.maxViscosityValue('')
        break;
      case 'pressure-atmospheric':
        props.pressureAtmospheric(event.target.value);
        break;
      case 'min-vapor-pressure':
        props.minVapor(event.target.value);
        dispatch({ type: 'minVaporValue', payload: event.target.value });
        props.minVaporValue('')
        break;
      case 'max-flow-acc':
        props.maxFlowAcc(event.target.value);
        break;
      case 'max-pressure-drop':
        props.maxPressureDrop(event.target.value);
        break;
      case 'gas-pressure':
        props.gasPressure(event.target.value);
        break;
      case 'gas-temperature':
        props.gasTemperature(event.target.value);
        
        break;
      case 'gas-density':
        props.gasDensity(event.target.value);
        if (event.target.value === '') {
          props.userEntered('CLEARID' + event.target.id)
        }
        if (props.fluid_state.toLowerCase() === 'gas' && props.gas_density !== '' && event.target.value !== '') {
          props.userEntered('baseRefDensity')
        }
        props.gasDensityValue('')
        break;
      case 'sonic-velocity':
        props.sonicVelocity(event.target.value);
        props.sonicVelocityValue('')
        break;
      case 'compressibility':
        if(state.gasCustomCompressibility !== 'border-disabled'){
          props.gasCompressibility(event.target.value);
        }
        if(event.target.value === ''){
          props.userEntered('CLEARID' + event.target.id)
        }
        if(props.fluid_state.toLowerCase()=== 'gas' && props.compressibility !=='' && event.target.value !== ''){
          props.userEntered('compressibilitySG')
        }

        break;
      case 'base-compressibility':
        props.baseCompressibility(event.target.value);
        break;
      case 'k-flow-consistency-index':
        props.kFlowConsistencyIndex(event.target.value);
        break;
      case 'n-flow-behavior':
        props.nFlowBehavior(event.target.value);
        break;
      default:
    }
  };
  const inputWithTooltip = (
    errorMessage,
    className,
    insertValues,
    value,
    id
  ) => {
    return (
      <>
        {errorMessage ? (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={`tooltip-error-${id}`} className='error-tooltip'>
                <FormatOppConditionsErrorMessage message={errorMessage} />
              </Tooltip>
            }
          >
            <input
              id={id}
              className={[className, 'oc-input'].join(' ')}
              onChange={insertValues}
              onBlur={(e) => handleBlur(e)}
              value={value}
              readOnly={className === 'border-disabled'}
              autoComplete='off'
            />
          </OverlayTrigger>
        ) : (
          <input
            id={id}
            className={[className, 'oc-input'].join(' ')}
            onChange={insertValues}
            onBlur={(e) => handleBlur(e)}
            value={value}
            readOnly={className === 'border-disabled'}
            autoComplete='off'
          />
        )}
      </>
    );
  };
  const renderbasetableOnSmallScreen = () => {
    return (
      <Container fluid>
        <>
          <Row auto>
            <Col
              sm={12}
              xs={12}
              colSpan='2'
              className='flow-rate-heading-small'
            >
              <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_BRCPRE' />
            </Col>
          </Row>
          <div className='mt-2'></div>
          <Table borderless className='fluid-properties-table'>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                </td>
                <td>
                  <input
                    id='gas-pressure'
                    className={[gasPressure, 'fp-input'].join(' ')}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={
                      gasPressure !== 'border-disabled'
                        ? props.gas_pressure
                        : props.gas_pressure
                    }
                    readOnly={gasPressure === 'border-disabled'}
                    autoComplete='off'
                  />
                </td>
              </tr>

              <tr>
                <td>
                  {' '}
                  <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                </td>
                <td>
                  {unitsDropdown(
                    '',
                    'units-gas-pressure',
                    getUnitsKeyValue(
                      props.units_gas_pressure,
                      VAPOR_PRESSURE_VALUES,
                      props.units_data
                    ).value,
                    optionsUnitsGasPressure,
                    handleUnitsGasPressureChange
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <Row auto>
            <Col
              sm={12}
              xs={12}
              colSpan='2'
              className='flow-rate-heading-small'
            >
              <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_BRCTEMP' />
            </Col>
          </Row>
          <div className='mt-2'></div>
          <Table borderless className='fluid-properties-table'>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                </td>
                <td>
                  <input
                    id='gas-temperature'
                    className={[gasTemperature, 'fp-input'].join(' ')}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={
                      state.gasTemperature !== 'border-disabled'
                        ?  props.gas_temperature
                        : ''
                    }
                    readOnly={gasTemperature === 'border-disabled'}
                    autoComplete='off'
                  />
                </td>
              </tr>

              <tr>
                <td>
                  {' '}
                  <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                </td>
                <td>
                  {unitsDropdown(
                    '',
                    'units-gas-temperature',
                    getUnitsKeyValue(
                      props.units_gas_temperature,
                      TEMPERATURE_VALUES,
                      props.units_data
                    ).value,
                    optionsUnitsGasTemperature,
                    handleUnitsGasTemperatureChange
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <Row auto>
            <Col
              sm={12}
              xs={12}
              colSpan='2'
              className='flow-rate-heading-small'
            >
              <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_DEN' />
            </Col>
          </Row>
          <div className='mt-2'></div>
          <Table borderless className='fluid-properties-table'>
            <tbody>
              <tr>
                <td>
                  {' '}
                  <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                </td>
                <td>
                {inputWithTooltip(
                    state.gasDensityError,
                    state.gasDensity,
                    insertValues,
                    state.gasDensity === 'border-disabled' ? props.gas_density !==''? 
                    parseFloat(props.gas_density).toFixed(props.all_other_decimal):'':props.gas_density,
                    'gas-density'
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {' '}
                  <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                </td>
                <td>
                {unitsDropdown(
                    '',
                    'units-gas-density',
                    props.units_gas_density,
                    state.gasDensityDropdownUnits,
                    handleUnitsGasDensityChange
                  )}
                </td>
              </tr>
              {
                state.showBaseCompressibilityRow?
                <tr>
                  <td className='gas-property-heading'>
                    <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_BRCCOMP' />
                  </td>
                  <td>
                    <input
                      id='base-compressibility'
                      className={[state.baseCompressibility, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={
                        state.baseCompressibility !== 'border-disabled'
                        ? props.base_compressibility
                        : props.base_compressibility
                      }
                      readOnly={state.baseCompressibility === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>:
                <tr/>
                }
            </tbody>
          </Table>
        </>
      </Container>
    );
  };
  const rendertableOnSmallScreen = () => {
    return (
      <Container fluid>
        {props.units_density_heading.toLowerCase() === 'density' ? (
          <>
            <Row auto>
              <Col sm={12} xs={12} colSpan='2' className='density-heading'>
                {unitsDropdown(
                  '',
                  'units-density-heading',
                  props.units_density_heading,
                  optionsUnitsDensityHeading,
                  handleUnitsDensityHeadingChange,
                  state.densityHeadingDisabled,
                  false,
                  false,
                  true
                )}
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    {props.calculate_density_variation ? (
                      inputWithTooltip(
                        state.minDensityError,
                        state.densityMin,
                        insertValues,
                        props.min_density,
                        'min-density'
                      )
                    ) : (
                      <input
                        id='min-density'
                        className={[state.densityMin, 'fp-input'].join(' ')}
                        onChange={insertValues}
                        onBlur={handleBlur}
                        value={state.densityMin === 'border-disabled' ? props.min_density !==''? parseFloat(props.min_density).toFixed(props.all_other_decimal):'' : props.min_density}
                        readOnly={state.densityMin === 'border-disabled'}
                        autoComplete='off'
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_NL1' />
                  </td>
                  <td className='fluidProp_float'>
                    {inputWithTooltip(
                      state.normalDensityError,
                      state.densityNormal,
                      insertValues,
                      state.densityNormal === 'border-disabled'? props.normal_density !==''? parseFloat(props.normal_density).toFixed(props.all_other_decimal):'' : props.normal_density,
                      'normal-density'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MX1' />
                  </td>
                  <td>
                    {props.calculate_density_variation ? (
                      inputWithTooltip(
                        state.maxDensityError,
                        state.densityMax,
                        insertValues,
                        state.densityMax === 'border-disabled' ? props.max_density !==''? parseFloat(props.max_density).toFixed(props.all_other_decimal):'':props.max_density,
                        'max-density'
                      )
                    ) : (
                      inputWithTooltip(
                        state.maxDensityError,
                        state.densityMax,
                        insertValues,
                        state.densityMax === 'border-disabled' ? props.max_density !==''? parseFloat(props.max_density).toFixed(props.all_other_decimal):'':props.max_density,
                        'max-density'
                      )

                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                  </td>
                  <td>
                    {unitsDropdown(
                      '',
                      'units-density',
                      props.units_density,
                      state.densityDropdownUnits,
                      handleUnitsDensityChange
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : props.units_density_heading.toLowerCase() === 'specific gravity' ? (
          <tr>
            <div>
            <td colSpan='2' className='specific-gravity-heading'>
              {unitsDropdown(
                '',
                'units-density-heading',
                props.units_density_heading,
                optionsUnitsDensityHeading,
                handleUnitsDensityHeadingChange,
                state.densityHeadingDisabled,
                false,
                false,
                true
              )}
            </td>
            </div>
            <div>
            <td>&nbsp;</td>
            <td className='fluidProp_float'>
              {inputWithTooltip(
                state.specificGravityError,
                state.specificGravity,
                insertValues,
                props.specific_gravity,
                'specific-gravity'
              )}
            </td>
            <td>&nbsp;</td>
            <td colSpan='3' className='align-left'>
              @60F and 14.7psia
            </td>
            </div>
          </tr>
        ) : (
          <tr>
            <tr>
            <td colSpan='2' className='molecular-weight-heading'>
              {unitsDropdown(
                '',
                'units-density-heading',
                props.units_density_heading,
                optionsUnitsDensityHeading,
                handleUnitsDensityHeadingChange,
                state.densityHeadingDisabled,
                false,
                false,
                true
              )}
            </td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td>
            {inputWithTooltip(
                    state.molecularWeightError,
                    state.molecularWeight,
                    insertValues,
                    props.molecular_weight,
                    'molecular-weight'
                  )}
            </td>
            <td colSpan='3'>&nbsp;</td>
            </tr>
          </tr>
        )}
        {
          state.showDensityRow? 
          <>
            <Row auto>
              <Col
              sm={12}
              xs={12}
              colSpan='2'
              className='flow-rate-heading-small'
              >
                <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_BRCDEN"/>
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_NL1' />
                  </td>
                  <td>
                  {inputWithTooltip(
                    state.densitySGError,
                    state.densitySG,
                    insertValues,
                    state.densitySG === 'border-disabled' ? props.density_specific_gravity !==''? 
                    parseFloat(props.density_specific_gravity).toFixed(props.all_other_decimal):'': props.density_specific_gravity,
                    'densitySG'
                  )}
                  </td>
                </tr>
                <tr>
                <td>
                  {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                  </td>
                  <td>
                  {unitsDropdown(
                    '',
                    'units-gas-densitySG',
                    getUnitsKeyValue(
                      props.units_gas_density_sg,
                      DENSITY_VALUES, 
                      props.units_data
                    ),
                    state.gasDensitySGDropdownUnits,
                    handleUnitsGasDensitySGChange
                  )}
                </td>
                </tr>
              </tbody>
            </Table>
          </>:
           <></>
        }

        {state.showViscosityRow ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_VIS' />
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    <input
                      id='min-viscosity'
                      className={[state.viscosityMin, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={state.viscosityMin === 'border-disabled' ? props.min_viscosity !==''? parseFloat(props.min_viscosity).toFixed(props.all_other_decimal):'': props.min_viscosity}
                      readOnly={state.viscosityMin === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_NL1' />
                  </td>
                  <td>
                    {inputWithTooltip(
                      state.normalViscosityError,
                      state.viscosityNormal,
                      insertValues,
                      props.fluid_source.toLowerCase()!=='natural gas'?
                    state.viscosityNormal === 'border-disabled' ? props.normal_viscosity !==''? 
                    parseFloat(props.normal_viscosity).toFixed(props.all_other_decimal):'':props.normal_viscosity
                    : props.normal_viscosity_value ===''?
                    props.normal_viscosity : parseFloat(props.normal_viscosity).toFixed(props.all_other_decimal),
                      'normal-viscosity'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MX1' />
                  </td>
                  <td>
                    <input
                      id='max-viscosity'
                      className={[state.viscosityMax, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={
                        state.viscosityMax === 'border-disabled' ? props.max_viscosity  !==''? parseFloat(props.max_viscosity ).toFixed(props.all_other_decimal):'': props.max_viscosity  }
                      readOnly={state.viscosityMax === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                  </td>
                  <td>
                    {unitsDropdown(
                      '',
                      'units-viscosity',
                      getUnitsKeyValue(
                        props.units_viscosity,
                        VISCOSITY_VALUES,
                        props.units_data
                      ),
                      state.viscosityDropdownUnits,
                      handleUnitsViscosityChange
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <span />
        )}
        {state.showPressureAtmosphericRow ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_ATMPRE2' />
                <CustomTranslation data='XXSP,ECIFY_MMI_MC_TXT_ATMPRE1' />
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    <input
                      id='pressure-atmospheric'
                      className={[state.atmosphericPressure, 'fp-input'].join(
                        ' '
                      )}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={
                        state.atmosphericPressure !== 'border-disabled'
                          ? props.pressure_atmospheric
                          : ''
                      }
                      readOnly={state.atmosphericPressure === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                  </td>
                  <td colSpan='2'>
                    {unitsDropdown(
                      '',
                      'units-pressure-atmospheric',
                      getUnitsKeyValue(
                        props.units_pressure_atmospheric,
                        VAPOR_PRESSURE_VALUES,
                        props.units_data
                      ),
                      state.vaporPressureDropdownUnits,
                      handleUnitsPressureAtmosphericChange
                    )}
                  </td>
                  <td colSpan='2'>&nbsp;</td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <span />
        )}
        {state.showVaporPressureRow ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_VAP' />
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    <input
                      id='min-vapor-pressure'
                      className={[state.vaporPressureMin, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={props.select_technology.toLowerCase()!=='vortex'?
                      state.vaporPressureMin === 'border-disabled' ? props.min_vapor  !==''? parseFloat(props.min_vapor ).toFixed(props.all_other_decimal):'':props.min_vapor:
                      props.min_vapor  !==''? props.min_vapor_value!==''? parseFloat(props.min_vapor ).toFixed(props.all_other_decimal):props.min_vapor :''}
                      readOnly={state.vaporPressureMin === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                  </td>
                  <td>
                    {unitsDropdown(
                      '',
                      'units-vapor-pressure',
                      getUnitsKeyValue(
                        props.units_vapor,
                        VAPOR_PRESSURE_VALUES,
                        props.units_data
                      ),
                      state.vaporPressureDropdownUnits,
                      handleUnitsVaporPressureChange
                    )}
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <span />
        )}
        {state.showMaxFlowAccuracyRow ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_MFA' />
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    <input
                      id='max-flow-acc'
                      className={[state.flowAccMax, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={
                        state.flowAccMax !== 'border-disabled'
                          ? props.max_flow_acc
                          : ''
                      }
                      readOnly={state.flowAccMax === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span className='align-left'>
                      <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MFAUNIT' />
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <span />
        )}
        {state.showMaxPressureDropRow ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_MPD2' />
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    <input
                      id='max-pressure-drop'
                      className={[state.pressureDropMax, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={
                        state.pressureDropMax !== 'border-disabled'
                          ? props.max_pressure_drop
                          : ''
                      }
                      readOnly={state.pressureDropMax === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                  </td>
                  <td>
                    {unitsDropdown(
                      '',
                      'units-max-pressure-drop',
                      props.units_line_pressure,
                      state.maxPressureDropdownUnits,
                      handleUnitsMaxPressureDropChange,
                      true,
                      false,
                      false,
                      true
                    )}
                  </td>
                </tr>
                <tr>
                <td></td>
                  <td>
                    <span className='align-left'>
                      <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MFAUNIT2' />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <span />
        )}
        {state.showMinAccuracyRow ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                {CustomTranslationText('XXSPECIFY_MMI_MINIMUM_ACC_TEST', props).toUpperCase() }
              </Col>
            </Row>
            <div className='mt-2'>\</div>
            <Table borderless className='fluid-properties-table'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                  </td>
                  <td>
                    {unitsDropdown(
                      '',
                      'units-minimum-accuracy',
                      props.units_minimum_accuracy,
                      optionsMinimumAccuracyDrop,
                      handleUnitsMinimumAccuracyChange
                    )}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <span />
        )}
        {state.showSonicVelocityRow ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                 {CustomTranslationText('XXSPECIFY_MMI_SR_LBL_SONIC', props).toUpperCase() }
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    <input
                      id='sonic-velocity'
                      className={[state.velocitySonic, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={state.velocitySonic === 'border-disabled' ? props.sonic_velocity !==''? 
                      parseFloat(props.sonic_velocity).toFixed(props.all_other_decimal):'':props.sonic_velocity}
                      readOnly={state.velocitySonic === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
                  </td>
                  <td colSpan='2'>
                    {unitsDropdown(
                      '',
                      'units-sonic-velocity',
                      getUnitsKeyValue(
                        props.units_sonic_velocity,
                        SONIC_VELOCITY_VALUES,
                        props.units_data
                      ).value,
                      optionsUnitsSonicVelocity,
                      handleUnitsSonicVelocityChange
                    )}
                  </td>
                  <td colSpan='2'>&nbsp;</td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <span />
        )}
        {state.showCompressibility ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                {CustomTranslationText('XXSPECIFY_MMI_MC_TXT_BRCCOMP', props).toUpperCase() }
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                  <input
                    id='compressibility'
                    className={[state.gasCustomCompressibility, 'fp-input'].join(' ')}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={
                      
                      props.units_density_heading.toLowerCase() !== 'density' ?
                      state.gasCompressibility !== 'border-disabled'
                        ? props.compressibility
                        : ''
                        : props.compressibility ? props.compressibility:''
                    }
                    readOnly={state.gasCompressibility === 'border-disabled'}
                    autoComplete='off'
                  />
                  </td>
                  <td colSpan='4'>&nbsp;</td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <span />
        )}
        {props.non_newtonian_liquid ? (
          <>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
               {CustomTranslationText('XXSPECIFY_MMI_MC_LBL_FCI', props).toUpperCase() }
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    {inputWithTooltip(
                      state.kFlowConsistencyIndexError,
                      state.kFlowConsistencyIndex,
                      insertValues,
                      props.k_flow_consistency_index,
                      'k-flow-consistency-index'
                    )}
                  </td>
                  <td className='k-flow-consistency-index-cp' colSpan='4'>
                  {/* {CustomTranslationText('XXSPECIFY_MMI_MC_LBL_PASN', props).toUpperCase() } */}
                  {outputstring}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Row auto>
              <Col
                sm={12}
                xs={12}
                colSpan='2'
                className='flow-rate-heading-small'
              >
                 {CustomTranslationText('XXSPECIFY_MMI_MC_LBL_FBEHAV', props).toUpperCase() }
              </Col>
            </Row>
            <div className='mt-2'></div>
            <Table borderless className='fluid-properties-table'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
                  </td>
                  <td>
                    {inputWithTooltip(
                      state.nFlowBehaviorError,
                      state.nFlowBehavior,
                      insertValues,
                      props.n_flow_behavior,
                      'n-flow-behavior'
                    )}
                  </td>
                  <td colSpan='4'>&nbsp;</td>
                </tr>
              </tbody>
            </Table>{' '}
          </>
        ) : (
          <></>
        )}
      </Container>
    );
  };
  return (
    <Container fluid>
      <div className='fluid-properties-heading'>
        <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_FLUID_PRO' />
      </div>
      {state.showNonNewtonianCheckbox ? (
        <div className='non-newtonian-checkbox'>
          <CustomCheckbox
            checked={props.non_newtonian_liquid}
            name='non-newtonian-liquid'
            id='non-newtonian-checkbox'
            className='custom-checkbox'
            onChange={(e) => toggleNonNewtonLiquidHandle(e.target.checked)}
            disabled={!state.showNonNewtonianCheckbox}
            label={<CustomTranslation data='XXSPECIFY_MMI_MC_LBL_NONNWE' />}
          />
        </div>
      ) : (
        <div />
      )}
      <div className='operating-conditions d-none d-sm-block'>
        <Table borderless className='fluid-properties-table'>
          <thead>
            <tr className='fluid-properties-table-header'>
              <th colSpan='2'>&nbsp;</th>
              <th className='font-weight-normal fp-table-heading-min'>
                {' '}
                <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MN1' />
              </th>
              <th className='font-weight-normal fp-table-heading-norm fluid-min'>
                {' '}
                <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_NL1' />
              </th>
              <th className='font-weight-normal fp-table-heading-max fluid-max'>
                {' '}
                <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MX' />
              </th>
              <th
                className='font-weight-normal fp-table-heading-units fluid-unit'
                colSpan='2'
              >
                {' '}
                <CustomTranslation data='XXSPECIFY_MMI_MC_DROPDN_UOM1' />
              </th>
            </tr>
          </thead>
          <tbody>
            {props.units_density_heading.toLowerCase() === 'density' ? (
              <tr>
                <td colSpan='2' className='density-heading'>
                  {unitsDropdown(
                    '',
                    'units-density-heading',
                    props.units_density_heading,
                    optionsUnitsDensityHeading,
                    handleUnitsDensityHeadingChange,
                    state.densityHeadingDisabled,
                    false,
                    false,
                    true
                  )}
                </td>
                <td>
                  {props.calculate_density_variation ? (
                    inputWithTooltip(
                      state.minDensityError,
                      state.densityMin,
                      insertValues,
                      state.densityMin === 'border-disabled' ? props.min_density !==''? parseFloat(props.min_density).toFixed(props.all_other_decimal):'' : props.min_density,
                      'min-density'
                    )
                  ) : (
                    <input
                      id='min-density'
                      className={[state.densityMin, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={state.densityMin === 'border-disabled' ? props.min_density !==''? parseFloat(props.min_density).toFixed(props.all_other_decimal):'' : props.min_density}
                      readOnly={state.densityMin === 'border-disabled'}
                      autoComplete='off'
                    />
                  )}
                </td>
                <td className='fluidProp_float'>
                  {inputWithTooltip(
                    state.normalDensityError,
                    state.densityNormal,
                    insertValues,
                    state.densityNormal === 'border-disabled'? props.normal_density !==''? parseFloat(props.normal_density).toFixed(props.all_other_decimal):'' : props.normal_density,
                    'normal-density'
                  )}
                </td>
                <td className='fluidProp_position'>
                  {props.calculate_density_variation ? (
                    inputWithTooltip(
                      state.maxDensityError,
                      state.densityMax,
                      insertValues,
                      state.densityMax === 'border-disabled' ? props.max_density !==''? parseFloat(props.max_density).toFixed(props.all_other_decimal):'':props.max_density,
                      'max-density'
                    )
                  ) : (

                    inputWithTooltip(
                      state.maxDensityError,
                      state.densityMax,
                      insertValues,
                      state.densityMax === 'border-disabled' ? props.max_density !==''? parseFloat(props.max_density).toFixed(props.all_other_decimal):'':props.max_density,
                      'max-density'
                    )

                   
                  )}
                </td>
                <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-density',
                    props.units_density,
                    state.densityDropdownUnits,
                    handleUnitsDensityChange
                  )}
                </td>
              </tr>
            ) : props.units_density_heading.toLowerCase() === 'specific gravity' ? (
              <tr>
                <td colSpan='2' className='specific-gravity-heading'>
                  {unitsDropdown(
                    '',
                    'units-density-heading',
                    props.units_density_heading,
                    optionsUnitsDensityHeading,
                    handleUnitsDensityHeadingChange,
                    state.densityHeadingDisabled,
                    false,
                    false,
                    true
                  )}
                </td>
                <td>&nbsp;</td>
                <td className='fluidProp_float'>
                  {inputWithTooltip(
                    state.specificGravityError,
                    state.specificGravity,
                    insertValues,
                    props.specific_gravity,
                    'specific-gravity'
                  )}
                </td>
                <td colSpan='3' className='align-left'>
                  @60F and 14.7psia
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan='2' className='molecular-weight-heading'>
                  {unitsDropdown(
                    '',
                    'units-density-heading',
                    props.units_density_heading,
                    optionsUnitsDensityHeading,
                    handleUnitsDensityHeadingChange,
                    state.densityHeadingDisabled,
                    false,
                    false,
                    true
                  )}
                </td>
                <td>&nbsp;</td>
                <td>
                  {inputWithTooltip(
                    state.molecularWeightError,
                    state.molecularWeight,
                    insertValues,
                    props.molecular_weight,
                    'molecular-weight'
                  )}
                </td>
                <td colSpan='3'>&nbsp;</td>
              </tr>
            )}
            {
              state.showDensityRow? 
              <tr>
                <td colSpan='2' className='density-heading fluid-property-heading'>
                  <CustomTranslation data="XXSPECIFY_MMI_MC_TXT_BRCDEN"/>
                </td>
                <td>&nbsp;</td>
                <td className='fluidProp_float'>
                {inputWithTooltip(
                    state.densitySGError,
                    state.densitySG,
                    insertValues,
                    state.densitySG === 'border-disabled' ? props.density_specific_gravity !==''? 
                    parseFloat(props.density_specific_gravity).toFixed(props.all_other_decimal):'': props.density_specific_gravity,
                    'densitySG'
                  )}
                </td>
                <td className='fluidProp_position'>&nbsp;</td>
                <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-gas-densitySG',
                    getUnitsKeyValue(
                      props.units_gas_density_sg,
                      DENSITY_VALUES, 
                      props.units_data
                    ),
                    state.gasDensitySGDropdownUnits,
                    handleUnitsGasDensitySGChange
                  )}
                </td>
              </tr> 
              : <></> 
            }
            {state.showViscosityRow ? (
              <tr>
                <td
                  colSpan='2'
                  className='viscosity-heading fluid-property-heading'
                >
                  <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_VIS' />
                </td>
                <td>
                {inputWithTooltip(
                    state.minViscosityError,
                    state.viscosityMin,
                    insertValues,
                    state.viscosityMin === 'border-disabled' ? props.min_viscosity !==''? parseFloat(props.min_viscosity).toFixed(props.all_other_decimal):'': props.min_viscosity,
                    'min-viscosity'
                  )}
                </td>
                <td className='fluidProp_float'>
                  {inputWithTooltip(
                    state.normalViscosityError,
                    state.viscosityNormal,
                    insertValues,
                    props.fluid_source.toLowerCase()!=='natural gas'?
                    state.viscosityNormal === 'border-disabled' ? props.normal_viscosity !==''? 
                    parseFloat(props.normal_viscosity).toFixed(props.all_other_decimal):'':props.normal_viscosity
                    : props.normal_viscosity_value ===''?
                    props.normal_viscosity : parseFloat(props.normal_viscosity).toFixed(props.all_other_decimal),
                    'normal-viscosity'
                  )}
                </td>
                <td className='fluidProp_position'>
                {inputWithTooltip(
                    state.maxViscosityError,
                    state.viscosityMax,
                    insertValues,
                    state.viscosityMax === 'border-disabled' ? props.max_viscosity  !==''? parseFloat(props.max_viscosity ).toFixed(props.all_other_decimal):'': props.max_viscosity ,
                    'max-viscosity'
                  )}
                </td>
                <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-viscosity',
                    getUnitsKeyValue(
                      props.units_viscosity,
                      VISCOSITY_VALUES,
                      props.units_data
                    ),
                    state.viscosityDropdownUnits,
                    handleUnitsViscosityChange
                  )}
                </td>
              </tr>
            ) : (
              <tr />
            )}
            {state.showPressureAtmosphericRow ? (
              <tr>
                <td
                  colSpan='2'
                  className='pressure-atmospheric-heading fluid-property-heading'
                >
                  <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_ATMPRE2' />
                  <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_ATMPRE1' />
                </td>
                <td>
                  <input
                    id='pressure-atmospheric'
                    className={[state.atmosphericPressure, 'fp-input'].join(
                      ' '
                    )}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={
                      state.atmosphericPressure !== 'border-disabled'
                        ? props.pressure_atmospheric
                        : ''
                    }
                    readOnly={state.atmosphericPressure === 'border-disabled'}
                    autoComplete='off'
                  />
                </td>
                <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-pressure-atmospheric',
                    getUnitsKeyValue(
                      props.units_pressure_atmospheric,
                      VAPOR_PRESSURE_VALUES,
                      props.units_data
                    ),
                    state.vaporPressureDropdownUnits,
                    handleUnitsPressureAtmosphericChange
                  )}
                </td>
                <td colSpan='2'>&nbsp;</td>
              </tr>
            ) : (
              <tr />
            )}
            {state.showVaporPressureRow ? (
              <tr>
                <td
                  colSpan='2'
                  className='vapor-pressure-heading fluid-property-heading'
                >
                  <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_VAP' />
                </td>
                <td>
                  <input
                    id='min-vapor-pressure'
                    className={[state.vaporPressureMin, 'fp-input'].join(' ')}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={
                      props.select_technology.toLowerCase()!=='vortex'?
                      state.vaporPressureMin === 'border-disabled' ? 
                      props.min_vapor  !==''? parseFloat(props.min_vapor ).toFixed(props.all_other_decimal):'':props.min_vapor:
                      props.min_vapor  !==''? props.min_vapor_value!==''? parseFloat(props.min_vapor ).toFixed(props.all_other_decimal):props.min_vapor :''
                    }
                    readOnly={state.vaporPressureMin === 'border-disabled'}
                    autoComplete='off'
                  />
                </td>
                <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-vapor-pressure',
                    getUnitsKeyValue(
                      props.units_vapor,
                      VAPOR_PRESSURE_VALUES,
                      props.units_data
                    ),
                    state.vaporPressureDropdownUnits,
                    handleUnitsVaporPressureChange
                  )}
                </td>
                <td colSpan='2'>&nbsp;</td>
              </tr>
            ) : (
              <tr />
            )}
            {state.showMaxFlowAccuracyRow ? (
              <tr>
                <td
                  colSpan='2'
                  className='max-flow-acc-heading fluid-property-heading'
                >
                  <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_MFA' />
                </td>
                <td>
                  <input
                    id='max-flow-acc'
                    className={[state.flowAccMax, 'fp-input'].join(' ')}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={
                      state.flowAccMax !== 'border-disabled'
                        ? props.max_flow_acc
                        : ''
                    }
                    readOnly={state.flowAccMax === 'border-disabled'}
                    autoComplete='off'
                  />
                </td>
                <td>
                  <span className='align-left'>
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MFAUNIT' />
                  </span>
                </td>
                <td colSpan='3'>&nbsp;</td>
              </tr>
            ) : (
              <tr />
            )}
            {state.showMaxPressureDropRow ? (
              <tr>
                <td
                  colSpan='2'
                  className='max-pressure-drop-heading fluid-property-heading'
                >
                  <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_MPD2' />
                </td>
                <td>
                  <input
                    id='max-pressure-drop'
                    className={[state.pressureDropMax, 'fp-input'].join(' ')}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={
                      state.pressureDropMax !== 'border-disabled'
                        ? props.max_pressure_drop
                        : ''
                    }
                    readOnly={state.pressureDropMax === 'border-disabled'}
                    autoComplete='off'
                  />
                </td>
                <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-max-pressure-drop',
                    props.units_line_pressure,
                    state.maxPressureDropdownUnits,
                    handleUnitsMaxPressureDropChange,
                    true,
                    false,
                    false,
                    true
                  )}
                </td>
                <td>
                  <span className='align-left'>
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_MFAUNIT2' />
                  </span>
                </td>
                <td colSpan='2'>&nbsp;</td>
              </tr>
            ) : (
              <tr />
            )}
            {state.showMinAccuracyRow ? (
              <tr>
                <td
                  colSpan='3'
                  className='minimum-accuracy-heading fluid-property-heading'
                >
                  {CustomTranslationText('XXSPECIFY_MMI_MINIMUM_ACC_TEST', props).toUpperCase() }
                </td>
                <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-minimum-accuracy',
                    props.units_minimum_accuracy,
                    optionsMinimumAccuracyDrop,
                    handleUnitsMinimumAccuracyChange
                  )}
                </td>
                <td colSpan='2'>&nbsp;</td>
              </tr>
            ) : (
              <tr />
            )}
            {state.showSonicVelocityRow ? (
              <tr>
                <td
                  colSpan='2'
                  className='sonic-velocity-heading fluid-property-heading'
                >
                 {CustomTranslationText('XXSPECIFY_MMI_SR_LBL_SONIC', props).toUpperCase() }
                </td>
                <td>
                  <input
                    id='sonic-velocity'
                    className={[state.velocitySonic, 'fp-input'].join(' ')}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={state.velocitySonic === 'border-disabled' ? props.sonic_velocity !==''? 
                    parseFloat(props.sonic_velocity).toFixed(props.all_other_decimal):'':props.sonic_velocity}
                    readOnly={state.velocitySonic === 'border-disabled'}
                    autoComplete='off'
                  />
                </td>
                <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-sonic-velocity',
                    getUnitsKeyValue(
                      props.units_sonic_velocity,
                      SONIC_VELOCITY_VALUES,
                      props.units_data
                    ).value,
                    optionsUnitsSonicVelocity,
                    handleUnitsSonicVelocityChange
                  )}
                </td>
                <td colSpan='2'>&nbsp;</td>
              </tr>
            ) : (
              <tr />
            )}
            
            {state.showCompressibility ? (
              <tr>
                <td
                  colSpan='2'
                  className='compressibility-heading fluid-property-heading'
                >
                 {CustomTranslationText('XXSPECIFY_MMI_MC_TXT_BRCCOMP', props).toUpperCase() }
                </td>
                <td>
                  <input
                    id='compressibility'
                    className={[state.gasCustomCompressibility, 'fp-input'].join(' ')}
                    onChange={insertValues}
                    onBlur={handleBlur}
                    value={
                      
                      props.units_density_heading.toLowerCase() !== 'density' ?
                      state.gasCompressibility !== 'border-disabled'
                        ? props.compressibility
                        : ''
                        : props.compressibility ? props.compressibility:''
                    }
                    readOnly={state.gasCompressibility === 'border-disabled'}
                    autoComplete='off'
                  />
                </td>
                <td colSpan='4'>&nbsp;</td>
              </tr>
            ) : (
              <tr />
            )}
            {props.non_newtonian_liquid ? (
              <>
                <tr>
                  <td
                    colSpan='2'
                    className='k-flow-consistency-index-heading fluid-property-heading'
                  >
                    {CustomTranslationText('XXSPECIFY_MMI_MC_LBL_FCI', props).toUpperCase() }
                  </td>
                  <td>
                    {inputWithTooltip(
                      state.kFlowConsistencyIndexError,
                      state.kFlowConsistencyIndex,
                      insertValues,
                      props.k_flow_consistency_index,
                      'k-flow-consistency-index'
                    )}
                  </td>
                  <td className='k-flow-consistency-index-cp' colSpan='4'>
                  {/* {CustomTranslationText('XXSPECIFY_MMI_PR_ACT_TL_PA_SN', props).toUpperCase() } */}
                  {outputstring}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan='2'
                    className='n-flow-behavior-heading fluid-property-heading'
                  >
                    {CustomTranslationText('XXSPECIFY_MMI_MC_LBL_FBEHAV', props).toUpperCase() }
                  </td>
                  <td>
                    {inputWithTooltip(
                      state.nFlowBehaviorError,
                      state.nFlowBehavior,
                      insertValues,
                      props.n_flow_behavior,
                      'n-flow-behavior'
                    )}
                  </td>
                  <td colSpan='4'>&nbsp;</td>
                </tr>
              </>
            ) : (
              <tr />
            )}
          </tbody>
        </Table>
      </div>

      <div className='d-block d-sm-none'>
        <hr></hr>
        {rendertableOnSmallScreen()}
      </div>
      {props.base_reference_conditions ? (
        <>
          <div className='base-reference-conditions-heading'>
            <CustomTranslation data='XXSPECIFY_MMI_MC_PB_BRC' />
          </div>
          <div className='operating-conditions d-none d-sm-block'>
            <Table borderless className='base-reference-conditions-table'>
              <tbody>
                <tr>
                  <td colSpan='2' className='gas-property-heading'>
                    <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_BRCPRE' />
                  </td>
                  <td>
{/*
                    <input
                      id='gas-pressure'
                      className={[state.gasPressure, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={
                        state.gasPressure !== 'border-disabled'
                        ? props.gas_pressure
                        : props.gas_pressure
                      }
                      readOnly={state.gasPressure === 'border-disabled'}
                      autoComplete='off'
                    />

*/}
                    {inputWithTooltip(
                      state.gasPressureError,
                      state.gasPressure,
                      insertValues,
                      props.gas_pressure,
                      'gas-pressure'
                    )}
                  </td>
                  <td colSpan='2'>
                    {unitsDropdown(
                      '',
                      'units-gas-pressure',
                      getUnitsKeyValue(
                        props.units_gas_pressure,
                        VAPOR_PRESSURE_VALUES,
                        props.units_data
                      ).value,
                      props.units_data.vaporPressure,
                      handleUnitsGasPressureChange
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan='2' className='gas-property-heading'>
                    <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_BRCTEMP' />
                  </td>
                  <td>
        {/*
                    <input
                      id='gas-temperature'
                      className={[state.gasTemperature, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={
                        state.gasTemperature !== 'border-disabled'
                        ? props.gas_temperature
                        : props.gas_temperature
                      }
                      readOnly={state.gasTemperature === 'border-disabled'}
                      autoComplete='off'
                    />

      */}
                      {inputWithTooltip(
                      state.gasTemperatureError,
                      state.gasTemperature,
                      insertValues,
                      props.gas_temperature,
                      'gas-temperature'
                    )}
                  </td>
                  <td colSpan='2'>
                    {unitsDropdown(
                      '',
                      'units-gas-temperature',
                      getUnitsKeyValue(
                        props.units_gas_temperature,
                        TEMPERATURE_VALUES,
                        props.units_data
                      ).value,
                      props.units_data.temperature,
                      handleUnitsGasTemperatureChange
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan='2' className='gas-property-heading'>
                    <CustomTranslation data='XXSPECIFY_MMI_MC_LBL_DEN' />
                  </td>
                  <td>
                  {inputWithTooltip(
                    state.gasDensityError,
                    state.gasDensity,
                    insertValues,
                    state.gasDensity === 'border-disabled' ? props.gas_density !==''? 
                    parseFloat(props.gas_density).toFixed(props.all_other_decimal):'':props.gas_density,
                    'gas-density'
                  )}
                  </td>
                  <td colSpan='2'>
                  {unitsDropdown(
                    '',
                    'units-gas-density',
                    props.units_gas_density,
                    state.gasDensityDropdownUnits,
                    handleUnitsGasDensityChange, 
                    state.baseGasDensityHeadingDisabled,
                    false,
                    false,
                    true
                  )}
                  </td>
                </tr>
                {
                state.showBaseCompressibilityRow?
                <tr>
                <td colSpan='2' className='gas-property-heading'>
                    <CustomTranslation data='XXSPECIFY_MMI_MC_TXT_BRCCOMP' />
                  </td>
                  <td>
                    <input
                      id='base-compressibility'
                      className={[state.baseCompressibility, 'fp-input'].join(' ')}
                      onChange={insertValues}
                      onBlur={handleBlur}
                      value={
                        state.baseCompressibility !== 'border-disabled'
                        ? props.base_compressibility
                        : props.base_compressibility
                      }
                      readOnly={state.baseCompressibility === 'border-disabled'}
                      autoComplete='off'
                    />
                  </td>
                </tr>:
                <tr/>
                }
              </tbody>
            </Table>
          </div>
          <div className='d-block d-sm-none'>
            <hr></hr>
            {renderbasetableOnSmallScreen()}
          </div>
        </>
      ) : (
        <div />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  min_density: state.ProcessVariables.min_density,
  min_density_value: state.ProcessVariables.min_density_value,
  normal_density: state.ProcessVariables.normal_density,
  normal_density_value: state.ProcessVariables.normal_density_value,
  max_density: state.ProcessVariables.max_density,
  max_density_value: state.ProcessVariables.max_density_value,
  tank: state.ApplicationRequirements.tank,
  units_viscosity: state.ProcessVariables.units_viscosity,
  min_viscosity: state.ProcessVariables.min_viscosity,
  min_viscosity_value: state.ProcessVariables.min_viscosity_value,
  normal_viscosity: state.ProcessVariables.normal_viscosity,
  normal_viscosity_value: state.ProcessVariables.normal_viscosity_value,
  max_viscosity: state.ProcessVariables.max_viscosity,
  max_viscosity_value: state.ProcessVariables.max_viscosity_value,
  units_vapor: state.ProcessVariables.units_vapor,
  units_density: state.ProcessVariables.units_density,
  get_sizing_data: state.MySizings.get_sizing_data,
  units_line_pressure: state.ProcessVariables.units_line_pressure,
  max_pressure_drop: state.ProcessVariables.max_pressure_drop,
  units_density_heading: state.ProcessVariables.units_density_heading,
  units_pressure_atmospheric: state.ProcessVariables.units_pressure_atmospheric,
  pressure_atmospheric: state.ProcessVariables.pressure_atmospheric,
  units_minimum_accuracy: state.ProcessVariables.units_minimum_accuracy,
  units_sonic_velocity: state.ProcessVariables.units_sonic_velocity,
  sonic_velocity: state.ProcessVariables.sonic_velocity,
  sonic_velocity_value: state.ProcessVariables.sonic_velocity_value,
  custom_errors:state.ProcessVariables.custom_errors,
  non_newtonian_liquid: state.ProcessVariables.non_newtonian_liquid,
  non_newtonian_liquid_flag: state.ProcessVariables.non_newtonian_liquid_flag,
  k_flow_consistency_index: state.ProcessVariables.k_flow_consistency_index,
  n_flow_behavior: state.ProcessVariables.n_flow_behavior,
  compressibility: state.ProcessVariables.compressibility,
  allow_pref_base_ref: state.ProcessVariables.allow_pref_base_ref,
  gas_custom_compressibility_error: state.ProcessVariables.gas_custom_compressibility_error,
  specific_gravity: state.ProcessVariables.specific_gravity,
  specific_gravity_error: state.ProcessVariables.specific_gravity_error,
  molecular_weight: state.ProcessVariables.molecular_weight,
  molecular_weight_error: state.ProcessVariables.molecular_weight_error,
  min_vapor: state.ProcessVariables.min_vapor,
  min_vapor_value: state.ProcessVariables.min_vapor_value,
  max_flow_acc: state.ProcessVariables.max_flow_acc,
  base_reference_conditions: state.ProcessVariables.base_reference_conditions,
  units_gas_pressure: state.ProcessVariables.units_gas_pressure,
  gas_pressure: state.ProcessVariables.gas_pressure,
  units_gas_temperature: state.ProcessVariables.units_gas_temperature,
  gas_temperature: state.ProcessVariables.gas_temperature,
  units_gas_density: state.ProcessVariables.units_gas_density,
  units_gas_density_sg: state.ProcessVariables.units_gas_density_sg,
  gas_density: state.ProcessVariables.gas_density,
  gas_density_value: state.ProcessVariables.gas_density_value,
  flow: state.MeasurementType.flow,
  density: state.MeasurementType.density,
  density_specific_gravity: state.ProcessVariables.density_sg,
  density_specific_gravity_value: state.ProcessVariables.density_sg_value,
  viscosity: state.MeasurementType.viscosity,
  select_technology: state.MeasurementType.select_technology,
  fluid_source: state.FluidSelection.fluid_source,
  fluid_state: state.FluidSelection.fluid_state,
  saved_preferences: state.Preferences.saved_preferences,
  units_data: state.DropdownData.units_data,
  calculate_density_variation:
    state.ProcessVariables.calculate_density_variation,
    decimal_factor_pref: state.Preferences.decimal_factor_pref,
  uom_auto_conversion_pref: state.Preferences.uom_auto_conversion_pref,
  minimum_density_error:state.ProcessVariables.minimum_density_error,
  normal_density_error:state.ProcessVariables.normal_density_error,
  maximum_density_error:state.ProcessVariables.maximum_density_error,
  normal_viscosity_error: state.ProcessVariables.normal_viscosity_error,
  maximum_viscosity_error: state.ProcessVariables.maximum_viscosity_error,
  all_other_decimal: state.Preferences.all_other_decimal,
  gas_density_error:state.ProcessVariables.gas_density_error,
  min_line_pressure: state.ProcessVariables.min_line_pressure,
  normal_line_pressure: state.ProcessVariables.normal_line_pressure,
  max_line_pressure: state.ProcessVariables.max_line_pressure,
  min_temperature: state.ProcessVariables.min_temperature,
  normal_temperature: state.ProcessVariables.normal_temperature,
  fluid_selection_flag: state.FluidSelection.fluid_selection_flag,
  saved_preferences_flag: state.Preferences.saved_preferences_flag,
  max_temperature: state.ProcessVariables.max_temperature,
  translation_data: state.TranslationData.translation_data,
  base_compressibility: state.ProcessVariables.base_compressibility,
  reset_resizing_flag: state.ProcessVariables.reset_resizing_flag,
  base_compressibility_error: state.ProcessVariables.base_compressibility_error,
  gas_temperature_error: state.ProcessVariables.gas_temperature_error,
  gas_pressure_error: state.ProcessVariables.gas_pressure_error,
  min_viscosity_convertion_error: state.ProcessVariables.min_viscosity_convertion_error,
  normal_viscosity_convertion_error: state.ProcessVariables.normal_viscosity_convertion_error,
  max_viscosity_convertion_error: state.ProcessVariables.max_viscosity_convertion_error,
  convert_disable_button : state.ProcessVariables.convert_disable_button,
  clear_all_field: state.ClearAllField.clear_all_field,
  operating_conditions: state.ProcessVariables.operating_conditions,
  query_params: state.Preferences.query_params,
    fluid_selection_section: state.FluidSelection.fluid_selection_section,
    revert_non_newtonian_checkbox_flag: state.ProcessVariables.revert_non_newtonian_checkbox_flag,
});

export default connect(mapStateToProps, {
  unitsDensity,
  minDensity,
  minDensityValue,
  normalDensity,
  normalDensityValue,
  advancedLayout,
  maxDensity,
  maxDensityValue,
  unitsViscosity,
  minViscosity,
  minViscosityValue,
  normalViscosity,
  normalViscosityValue,
  maxViscosity,
  maxViscosityValue,
  unitsVapor,
  minVapor,
  minVaporValue,
  maxFlowAcc,
  unitsMaxPressureDrop,
  maxPressureDrop,
  unitsPressureAtmospheric,
  pressureAtmospheric,
  unitsDensityHeading,
  unitsMinimumAccuracy,
  unitsSonicVelocity,
  nonNewtonianLiquid,
  nonNewtonianLiquiFlag,
  kFlowConsistencyIndex,
  nFlowBehavior,
  unitsGasPressure,
  gasPressure,
  unitsGasTemperature,
  gasTemperature,
  baseCompressibility,
  unitsGasDensity,
  unitsGasDensitySG,
  gasDensity,
  gasDensityValue, 
  baseReferenceConditions,
  specificGravity,
  molecularWeight,
  sonicVelocity,
  sonicVelocityValue,
  gasCompressibility,
  clearAllField,
  requestSizingResults,
  displaySizingResults,
  wasClicked,
  savedSizingLoad,
  densitySG,
  densitySGValue,
  minViscosityConvertionError,
  normalViscosityConvertionError,
  maxViscosityConvertionError,
  convertDisableButton,
  resetResizingFlag,
  clearProcVariables,
  fluidSelectionSection,
  gasDensityError,
  normalDensityError,
  normalViscosityError,
  resetOperatingConditions, 
  revertNonNewtonianCheckboxFlag,
  normalDensityMinMaxErrors,
  maxDensityMinMaxErrors,
  normalViscosityMinMaxErrors,
  maxViscosityMinMaxErrors,
  normalDensityTypeErrors,
  maxDensityTypeErrors,
  normalViscosityTypeErrors,
  maxViscosityTypeErrors,
  gasTemperatureError,
  gasPressureError,
})(FluidProperties);
